
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Row extends Vue {
  @Prop({ default: '1em' })
  private readonly gutter!: number;

  @Prop({ default: 'flex-start' })
  private readonly verticalAlign!: 'flex-start' | 'flex-end' | 'center';

  @Prop({ default: 'space-between' })
  private readonly horizontalAlign!: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly';

  @Prop({ default: false })
  private readonly noMargin!: boolean;
}
