import BlogAnalyticalEventCreateDto from '../../../models/api/analytic-event/BlogAnalyticalEventCreateDto'
import BusinessBranchAnalyticEventCreateDto
    from '../../../models/api/analytic-event/BusinessBranchAnalyticEventCreateDto'
import IHttpClient from '../../httpclient/IHttpClient'
import APIBase from '../APIBase'

class AnalyticsEventApi extends APIBase {
    constructor(httpClient: IHttpClient) {
        super(httpClient);
    }

    CreateBusinessBranchAnalyticEvent(branchId: string, dto: BusinessBranchAnalyticEventCreateDto): Promise<void> {
        return this.POST({
            Path: `/analytic/branch/${branchId}`,
            Body: dto,
            Anon: true
        })
    }

    CreateBlogAnalyticEvent(id: string, dto: BlogAnalyticalEventCreateDto): Promise<void> {
        return this.POST({
            Path: `/analytic/blog/${id}`,
            Body: dto,
            Anon: true
        })
    }
}

export default AnalyticsEventApi
