import API from "./api/API";
import CredentialManager from "./credentialmanager/CredentialManager";
import EventBus from "./eventbus/EventBus";
import FormManager from './formManager/FormMananger'
import ServiceManager from "./ServiceManager";
import ServiceType from "./ServiceTypes";

class Services {
    private static _api: API | null = null;
    private static _credentialManager: CredentialManager | null = null;
    private static _eventBus: EventBus | null = null;
    private static _formManager: FormManager | null = null;

    public static get API(): API {
        if (this._api === null) this._api = ServiceManager.Require(ServiceType.API) as API;

        return this._api;
    }

    public static get CredentialManager(): CredentialManager {
        if (this._credentialManager === null) this._credentialManager = ServiceManager.Require(ServiceType.CredentialManager) as CredentialManager;

        return this._credentialManager;
    }

    public static get EventBus(): EventBus {
        if (this._eventBus === null) this._eventBus = ServiceManager.Require(ServiceType.EventBus) as EventBus;

        return this._eventBus;
    }

    public static get FormManager(): FormManager {
        if (this._formManager === null) this._formManager = ServiceManager.Require(ServiceType.FormManager) as FormManager;

        return this._formManager;
    }
}

export default Services;
