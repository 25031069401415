import Utils from "../../Utils";
import {Service} from "../IService";
import ServiceType from "../ServiceTypes";
import {EventData, EventDataObject} from "./EventData";
import EventHandler from "./EventHandler";
import EventHandlerCallback from "./EventHandlerCallback";

class EventBus implements Service {
    Type: ServiceType = ServiceType.EventBus;
    Id = 'EventBus';

    Listeners: Record<string, EventHandler[]> = {};

    Servers: Record<string, (data: EventDataObject) => any> = {};

    On(event: string, callback: EventHandlerCallback): string {
        if (this.Listeners[event] === undefined) this.Listeners[event] = []

        const handler: EventHandler = {
            Id: Utils.GetId(),
            Callback: callback
        }

        this.Listeners[event].push(handler)

        return handler.Id
    }

    Off(event: string, id: string): void {
        if (this.Listeners[event] === undefined) return

        const idx = this.Listeners[event].findIndex((eh) => eh.Id === id)

        if (idx !== -1) this.Listeners[event].splice(idx, 1)
    }

    Trigger(event: string, data: EventDataObject): void {
        if (this.Listeners[event] === undefined) return

        const eventData: EventData = {
            Event: event,
            Data: data
        }

        this.Listeners[event].forEach((listener) => {
            listener.Callback(eventData)
        })
    }

    RegisterServer(type: string, handler: (data: EventDataObject) => any): void {
        this.Servers[type] = handler
    }

    Request<TResponse>(type: string, data: EventDataObject): Promise<TResponse> {
        return new Promise((resolve, reject) => {
            const server = this.Servers[type];

            if (server === undefined) {
                reject(`No servers registered for type ${type}`)

                return
            }

            const result = server(data);

            resolve(result as TResponse);
        });
    }
}

export default EventBus
