
import { Vue, Component } from 'vue-property-decorator'
import ErrorPage from './components/collections/error/ErrorPage.vue'
import ErrorPageDetails from './components/collections/error/ErrorPageDetails.vue'

import './assets/css/reset.css'
import './assets/css/colours.css'
import './assets/css/fonts.css'
import './assets/css/layout.css'
import './assets/css/transitions.css'

@Component({
  components: {
    ErrorPage,
    ErrorPageDetails
  }
})
export default class App extends Vue {

}
