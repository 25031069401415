import {Service} from "../../IService";
import Services from '../../Services'
import ServiceType from "../../ServiceTypes";
import HttpError from "../HttpError";
import IHttpClient from "../IHttpClient";
import IHttpRequest from "../IHttpRequest";

class FetchClient implements Service, IHttpClient {
    Type: ServiceType = ServiceType.HttpClient;
    Id = 'FetchClient';

    private static BuildFetchRequest(req: IHttpRequest): Request {
        const fetchReq = new Request(req.BuildUrl(), {
            method: req.Method.toString(),
            mode: "cors",
            cache: req.CacheMode,
            headers: req.Headers,
            body: req.Body,
            credentials: "include"
        });

        return fetchReq;
    }

    Fetch(req: IHttpRequest): Promise<Response> {
        const fetchReq = FetchClient.BuildFetchRequest(req)

        return window.fetch(fetchReq)
    }

    Request<TResponse>(req: IHttpRequest): Promise<TResponse> {
        return this.Fetch(req).then((res) => {
            if (res.ok) {
                // if (res.status === 204)
                //   return Promise.resolve()
                if (res.headers.get("content-type") === "application/octet-stream")
                    return Promise.resolve(res.blob() as unknown as TResponse)
                if (res.headers.get("content-type") === "text/html") {
                    return Promise.resolve(res.text()) as unknown as TResponse
                }
                return res.json().then((data) => {
                    return Promise.resolve(data as TResponse)
                }).catch(() => {
                    return Promise.reject()
                })
            } else {
                switch (res.status) {
                    case 400: {
                        return res.json().then((data) => Promise.reject({Status: 400, ModelState: data} as HttpError))
                    }
                    case 401: {
                        Services.CredentialManager.DeleteOAuthCredential();
                    }
                }

                return Promise.reject({Status: res.status} as HttpError)
            }
        })
    }
}

export default FetchClient;
