import APIBase from '../../APIBase'
import {IHttpClient} from "../../.."
import BusinessBranchFilter from '../../../../filters/BusinessBranchFilter'
import {BusinessBranchModel} from '../../../../models/api/businesses'
import BusinessBranchStatusUpdateDto from '../../../../models/api/businesses/branches/BusinessBranchStatusUpdateDto'
import {BlogModel} from '../../../../models/api/blogs'
import BusinessCreateModel from '../../../../models/api/businesses/BusinessCreateModel'
import BusinessBranchWithFeedbackReadDto
  from '../../../../models/api/businesses/branches/Feedback/BusinessBranchWithFeedbackReadDto'
import BusinessBranchFeedbackFilter from "../../../../filters/BusinessBranchFeedbackFilter";

class AdminBusinessesAPI extends APIBase {

    constructor(httpClient: IHttpClient) {
        super(httpClient);
    }

    ExportBusinesses(filter: BusinessBranchFilter): Promise<Blob> {
        return this.GET({
            Path: '/admin/businesses/export',
            QueryParams: filter.ToQueryParams()
        })
    }

    QueryExpressBranches(): Promise<BusinessBranchModel[]> {
        return this.GET({
            Path: `/admin/businesses/branches/express`
        })
    }

    StatusUpdate(branchId: string, dto: BusinessBranchStatusUpdateDto): Promise<BusinessBranchModel> {
        return this.PATCH({
            Path: `/admin/businesses/branches/${branchId}/status`,
            Body: dto
        })
    }

    GetNewAndRenewedBranches(): Promise<BusinessBranchModel[]> {
        return this.GET({
            Path: '/admin/businesses/branches/new-renewed'
        })
    }

    GetLapsedMembers(): Promise<BusinessBranchModel[]> {
        return this.GET({
            Path: '/admin/businesses/branches/lapsed'
        })
    }

    MarkSeen(id: string): Promise<BusinessBranchModel> {
        return this.PATCH({
            Path: `/admin/businesses/branches/${id}/mark-seen`
        })
    }

    GetBlogsWaitingForApproval(): Promise<BlogModel[]> {
        return this.GET({
            Path: '/admin/blogs'
        })
    }

    Create(dto: BusinessCreateModel): Promise<BusinessBranchModel> {
        return this.POST({
            Path: '/admin/businesses/branches',
            Body: dto
        })
    }

    GetCancelledMembers(): Promise<BusinessBranchWithFeedbackReadDto[]> {
        return this.GET({
            Path: '/admin/businesses/branches/cancelled'
        })
    }

    CancelMembership(id: string): Promise<BusinessBranchModel> {
        return this.PATCH({
            Path: `/admin/businesses/branches/cancel/${id}`
        })
    }

    ExportFeedback (filter: BusinessBranchFeedbackFilter): Promise<Blob>
    {
        return this.GET({
            Path: '/admin/businesses/feedback/export',
            QueryParams: filter.ToQueryParams()
        })
    }

    ExpressSignupComplete(branchId: string): Promise<boolean>
    {
        return this.PATCH({
            Path: `/admin/businesses/express/${branchId}`
        })
    }

}

export default AdminBusinessesAPI;
