import {IHttpClient} from "../..";
import {CheckAccountExistsResponseModel, VerificationCodeValidationResultModel} from "../../../models/api/signup";
import VerificationTokenReadDto from "../../../models/api/signup/VerificationTokenReadDto"
import VerificationTokenCreateDto from "../../../models/api/signup/VerificationTokenCreateDto"
import APIBase from "../APIBase";
import PasswordResetRequest from '../../../models/api/signup/PasswordResetRequest'
import PasswordResetResponse from '../../../models/api/signup/PasswordResetResponse'
import SignUpModel from '../../../models/api/signup/SignupModel'
import SignUpUserAndBusinessResponse from '../../../models/api/signup/SignUpUserAndBusinessResponse'

class SignupAPI extends APIBase {
    constructor(httpClient: IHttpClient) {
        super(httpClient);
    }

    CheckRequirements(dto: SignUpModel): Promise<boolean> {
        return this.POST({
            Path: '/signup/check-requirements',
            Body: dto,
            Anon: true
        })
    }

    SignUpUserAndBusiness(dto: SignUpModel): Promise<SignUpUserAndBusinessResponse> {
        return this.POST({
            Path: '/signup/signup',
            Body: dto,
            Anon: true
        })
    }

    CheckAccountExists(email: string): Promise<CheckAccountExistsResponseModel> {
        return this.POST({
            Path: '/signup/account/exists',
            Body: {
                Email: email
            },
            Anon: true
        })
    }

    RequestAccountVerificationCode(email: string): Promise<VerificationTokenReadDto> {
        return this.POST({
            Path: '/signup/account/request_verification',
            Body: email,
            Anon: true
        })
    }

    ValidateAccountVerificationCode(tokenId: string, email: string, token: string): Promise<VerificationCodeValidationResultModel> {
        return this.POST({
            Path: `/signup/account/verify/${tokenId}`,
            Body: {
                Email: email,
                Token: token
            },
            Anon: true
        })
    }

    RequestVerificationCode(dto: VerificationTokenCreateDto): Promise<VerificationTokenReadDto> {
        return this.POST({
            Path: `/signup/account/verification-token`,
            Body: dto,
            Anon: true
        })
    }

    ResetPassword(request: PasswordResetRequest): Promise<PasswordResetResponse> {
        return this.POST({
            Path: `/signup/account/password-reset`,
            Body: request,
            Anon: true
        })
    }

}

export default SignupAPI
