import StateModule from './StateModule'

interface ENVState {
  errored: boolean;
}

class ENVModule implements StateModule<ENVState> {
  namespaced = true
  state = {
    errored: false
  }

  mutations = {
    setErrored (state: ENVState, errored: boolean): void {
      state.errored = errored
    }
  }
}

export { ENVModule, ENVState }
