
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Avatar extends Vue {
  @Prop()
  readonly image!: string

  @Prop()
  readonly text!: string;

  @Prop({ default: 'primary' })
  private type!: string;

  get firstChar (): string {
    return this.text ? this.text.substring(0, 1) : ''
  }
}
