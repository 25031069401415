import {Logger, LogLevel} from "../debug/Logger";
import IConfig from "./IConfig";
import APIConfig from "./modules/APIConfig";
import HttpConfig from "./modules/HttpConfig";
import LoggingConfig from "./modules/LoggingConfig";
import OAuthConfig from "./modules/OAuthConfig";
import WebsiteUrlsConfig from './modules/WebsiteAddressesConfig'

export default class Config {
    static API: APIConfig;
    static Http: HttpConfig;
    static Logging: LoggingConfig;
    static OAuth: OAuthConfig;
    static WebsiteUrls: WebsiteUrlsConfig;

    public static Initialise(config: IConfig): void {
        this.API = config.API;
        this.Http = config.Http;
        this.Logging = config.Logging;
        this.OAuth = config.OAuth;
        this.WebsiteUrls = config.WebsiteUrls

        Logger.Log(LogLevel.Debug, 'Environment', 'Initialised config');
    }
}
