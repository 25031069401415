import {BrowserStorageProvider} from "..";
import {OAuthTokenModel} from "../../models/api/auth/oauth";
import {Service} from "../IService";
import ServiceManager from "../ServiceManager";
import ServiceType from "../ServiceTypes";

class CredentialManager implements Service {
    Type: ServiceType = ServiceType.CredentialManager;
    Id = 'CredentialManager';
    StorageProvider: BrowserStorageProvider;
    private CachedOAuthCredential: OAuthTokenModel | null = null;

    constructor() {
        this.StorageProvider = ServiceManager.Require(ServiceType.BrowserStorage)
    }

    public DeleteOAuthCredential(): void {
        this.CachedOAuthCredential = null

        this.StorageProvider.removeItem('tm_oauth');
    }

    public SetOAuthCredential(token: OAuthTokenModel | null): void {
        this.CachedOAuthCredential = token

        this.StorageProvider.setItem('tm_oauth', token)
    }

    public GetOAuthCredential(): OAuthTokenModel | null {
        if (this.CachedOAuthCredential) return this.CachedOAuthCredential

        const token = this.StorageProvider.getItem('tm_oauth')

        if (token) {
            return token as OAuthTokenModel
        }

        return null
    }
}

export default CredentialManager;
