
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class ErrorPageDetails extends Vue {
  @Prop()
  private readonly status!: string;

  @Prop()
  private readonly message!: string;

  @Prop()
  private readonly notified!: boolean;

  get year (): number {
    return (new Date()).getFullYear()
  }
}
