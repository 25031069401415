import {IHttpClient} from "../..";
import APIBase from "../APIBase";
import {PagedResultSet} from "../../../models/api/common";
import QuoteRequest from "../../../models/api/quotes/QuoteRequest"
import QuoteRequestFilter from "../../../filters/QuoteRequestFilter";
import CreateQuoteRequestModel from '../../../models/api/quotes/CreateQuoteRequestModel'
import {BusinessBranchModel} from '../../../models/api/businesses'

class QuotesAPI extends APIBase {
    constructor(httpclient: IHttpClient) {
        super(httpclient)
    }

    GetQuoteRequest(quoteId: string, filter: QuoteRequestFilter): Promise<QuoteRequest> {
        return this.GET({
            Path: `/quote_requests/${quoteId}`,
            QueryParams: filter.ToQueryParams()
        })
    }

    GetQuoteRequests(filter: QuoteRequestFilter): Promise<PagedResultSet<QuoteRequest>> {
        return this.GET({
            Path: `/quote_requests/`,
            QueryParams: filter.ToQueryParams()
        })
    }

    FilterQuoteRequests(filter: QuoteRequestFilter): Promise<PagedResultSet<QuoteRequest>> {
        return this.GET({
            Path: '/quote_requests',
            QueryParams: filter.ToQueryParams()
        })
    }

    CreateQuoteRequest(createQuoteRequest: CreateQuoteRequestModel): Promise<BusinessBranchModel[]> {
        return this.POST({
            Anon: true,
            Path: '/quote_requests',
            Body: createQuoteRequest
        })
    }

    UpdateQuoteRequest(quoteId: string, quoteRequest: QuoteRequest, filter: QuoteRequestFilter): Promise<QuoteRequest> {
        return this.PATCH({
            Path: `/quote_requests/${quoteId}`,
            Body: quoteRequest,
            QueryParams: filter.ToQueryParams()
        })
    }

    ResolveSearch (quoteId: string): Promise<boolean> {
        return this.POST({
            Path: `/quote_requests/resolve-search/${quoteId}`,
            Anon: true
        })
    }
}

export default QuotesAPI
