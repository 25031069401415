import {IHttpClient} from "../..";
import BlogFilter from "../../../filters/BlogFilter";
import {BlogModel, CreateBlogModel, UpdateBlogModel} from "../../../models/api/blogs";
import {PagedResultSet} from "../../../models/api/common";
import APIBase from "../APIBase";
import PagedFilterBase from "../../../filters/PagedFilterBase";

class BlogAPI extends APIBase {
    constructor(httpClient: IHttpClient) {
        super(httpClient);
    }

    GetBlogBySlug(slug: string): Promise<BlogModel> {
        return this.GET({
            Anon: true,
            Path: `/blogs/${slug}`
        })
    }

    GetAllBlogs(filter: BlogFilter): Promise<PagedResultSet<BlogModel>> {
        return this.GET({
            Anon: true,
            Path: `/blogs/all`,
            QueryParams: filter.ToQueryParams()
        })
    }

    CreateBlog(model: CreateBlogModel): Promise<BlogModel> {
        return this.POST({
            Path: '/blogs',
            Body: model
        })
    }

    UpdateBlog(blogId: string, model: UpdateBlogModel): Promise<BlogModel> {
        return this.PATCH({
            Path: `/blogs/${blogId}`,
            Body: model
        })
    }

    DeleteBlog(blogId: string): Promise<boolean> {
        return this.DELETE({
            Path: `/blogs/${blogId}`
        })
    }

    GetBlogsForPublic(filter: BlogFilter): Promise<PagedResultSet<BlogModel>> {
        return this.GET({
            Anon: true,
            Path: '/blogs',
            QueryParams: filter.ToQueryParams()
        })
    }

    GetBlogsForConsole(filter: BlogFilter): Promise<PagedResultSet<BlogModel>> {
        return this.GET({
            Path: '/blogs',
            QueryParams: filter.ToQueryParams()
        })
    }

    GetBlogForPrivate(slug: string): Promise<BlogModel> {
        return this.GET({
            Path: `/blogs/${slug}/member`,
        })
    }

    NextBlog(blogId: string): Promise<BlogModel> {
        return this.GET({
            Path: `/blogs/${blogId}/next`,
        })
    }

    PreviousBlog(blogId: string): Promise<BlogModel> {
        return this.GET({
            Path: `/api/blogs/${blogId}/previous`,
        })
    }
}

export default BlogAPI
