import { BusinessBranchModel } from 'tradingmate-modules/src/models/api/businesses'
import { LoggedInUserModel } from 'tradingmate-modules/src/models/api/users'
import StateModule from './StateModule'

interface IdentityState {
  User: LoggedInUserModel | null;
  LoggedIn: boolean;
  BusinessBranch: BusinessBranchModel | null;
}

class IdentityModule implements StateModule<IdentityState> {
  namespaced = true

  state = {
    User: null,
    LoggedIn: false,
    BusinessBranch: null
  }

  mutations = {
    setLoggedIn (state: IdentityState, loggedIn: boolean): void {
      state.LoggedIn = loggedIn
    },
    setUser (state: IdentityState, user: LoggedInUserModel | null): void {
      state.User = user
      state.LoggedIn = user !== null
    },
    setBranch (state: IdentityState, businessBranch: BusinessBranchModel): void {
      state.BusinessBranch = businessBranch
    }
  }
}

export { IdentityModule, IdentityState }
