import {BrowserStorageProvider} from "..";
import FormSubmissionTokenModel from '../../models/api/auth/forms/FormSubmissionTokenModel'
import FormSubmissionToken from '../../models/api/auth/forms/FormSubmissionTokenModel'
import {Service} from "../IService";
import ServiceManager from "../ServiceManager";
import ServiceType from "../ServiceTypes";

class FormManager implements Service {
    Type: ServiceType = ServiceType.FormManager;
    Id = 'FormManager';
    StorageProvider: BrowserStorageProvider;
    private CachedFormSubmissionToken: FormSubmissionToken | null = null;

    constructor() {
        this.StorageProvider = ServiceManager.Require(ServiceType.BrowserStorage)
    }

    public DeleteFormSubmissionToken(): void {
        this.CachedFormSubmissionToken = null

        this.StorageProvider.removeItem('tm_form');
    }

    public SetFormSubmissionToken(token: FormSubmissionTokenModel | null): void {
        this.CachedFormSubmissionToken = token

        this.StorageProvider.setItem('tm_form', token)
    }

    public GetFormSubmissionToken(): FormSubmissionTokenModel | null {
        if (this.CachedFormSubmissionToken) return this.CachedFormSubmissionToken

        const token = this.StorageProvider.getItem('tm_form')

        if (token) {
            return token as FormSubmissionToken
        }

        return null
    }
}

export default FormManager;
