
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class LazyImage extends Vue {
  @Prop({ required: true })
  private readonly src!: string;

  @Prop({ default: '0.75' })
  private readonly ar!: string;
}
