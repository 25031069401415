import {IHttpClient} from "../..";
import {OfferCreateDto, OfferReadDto, OfferUpdateDto} from "../../../models/api/offers";
import {PagedResultSet} from "../../../models/api/common";
import APIBase from "../APIBase";
import OfferFilter from '../../../filters/OfferFilter'

export default class OfferAPI extends APIBase {
    constructor(httpClient: IHttpClient) {
        super(httpClient);
    }

    GetOfferBySlug(slug: string): Promise<OfferReadDto> {
        return this.GET({
            Path: `/offers/${slug}`
        })
    }

    CreateOffer(model: OfferCreateDto): Promise<OfferReadDto> {
        return this.POST({
            Path: '/offers',
            Body: model
        })
    }

    UpdateOffer(offerId: string, model: OfferUpdateDto): Promise<OfferReadDto> {
        return this.PATCH({
            Path: `/offers/${offerId}`,
            Body: model
        })
    }

    DeleteOffer(offerId: string): Promise<boolean> {
        return this.DELETE({
            Path: `/offers/${offerId}`
        })
    }

    GetOffersForConsole(filter: OfferFilter): Promise<PagedResultSet<OfferReadDto>> {
        return this.GET({
            Path: '/offers',
            QueryParams: filter.ToQueryParams()
        })
    }
}
