enum SocialLinkType {
    None,
    Facebook,
    Instagram,
    Linkedin,
    GoogleMyBusiness,
    Video,
    Twitter,
    TripAdvisor,
    Pinterest,
    Etsy,
    Website
}

export default SocialLinkType
