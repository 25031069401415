import QuoteRequestFilter from '../../../../filters/QuoteRequestFilter'
import AdminQuoteRequest from '../../../../models/api/admin/quotes/AdminQuoteRequest'
import {PagedResultSet} from '../../../../models/api/common/PagedResultSet'
import IHttpClient from '../../../httpclient/IHttpClient'
import APIBase from '../../APIBase'

class AdminQuoteRequestAPI extends APIBase {
    constructor(httpclient: IHttpClient) {
        super(httpclient)
    }

    GetAdminQuoteRequest(id: string): Promise<AdminQuoteRequest> {
        return this.GET({
            Path: `/admin/quote-request/${id}`,
        })
    }

    GetAdminQuoteRequests(filter: QuoteRequestFilter): Promise<PagedResultSet<AdminQuoteRequest>> {
        return this.GET({
            Path: '/admin/quote-request',
            QueryParams: filter.ToQueryParams()
        })
    }
}

export default AdminQuoteRequestAPI
