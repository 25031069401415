import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    // Helpers
    name: 'Renewal',
    path: '/helper/renewal/:id',
    meta: {
      allowAnon: true
    },
    component: () => import('../views/helper/Renewal.vue')
  },
  {
    // Helpers
    name: 'Quote Request Resolve Search',
    path: '/helper/resolve-search/:id',
    meta: {
      allowAnon: true
    },
    component: () => import('../views/helper/QuoteRequestResolveSearchForWebmaster.vue')
  },
  {
    // Account
    name: 'Account',
    path: '/account',
    component: () => import('../views/account/AuthPage.vue'),
    children: [
      {
        path: 'login',
        name: 'Login',
        meta: {
          allowAnon: true
        },
        component: () => import('../views/account/Login.vue')
      },
      {
        path: 'logout',
        name: 'Logout',
        component: () => import('../views/account/Logout.vue')
      },
      {
        path: 'reset',
        name: 'Reset Password',
        meta: {
          allowAnon: true
        },
        component: () => import('../views/account/ResetPassword.vue')
      }
    ]
  },
  {
    path: '/account/register',
    name: 'Register',
    meta: {
      allowAnon: true
    },
    component: () => import('../views/account/Register.vue')
  },
  // Dashboard
  {
    path: '/',
    // name: 'Console Page',
    component: () => import('../views/dashboard/ConsolePage.vue'),
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: () => import('../views/dashboard/Dashboard.vue')
      },
      // Profile
      {
        path: 'profile',
        name: 'Profile',
        component: () => import('../views/dashboard/Profile.vue')
      },
      // Blogs
      {
        path: 'blogs',
        name: 'Blog Index',
        component: () => import('../views/dashboard/blog/BlogIndex.vue')
      },
      {
        path: 'blog/new',
        name: 'Create Blog',
        component: () => import('../views/dashboard/blog/BlogCreate.vue')
      },
      {
        path: 'blog/:slug',
        name: 'Edit Blog',
        component: () => import('../views/dashboard/blog/BlogEdit.vue')
      },
      // Events
      {
        path: 'events',
        name: 'Events Index',
        component: () => import('../views/dashboard/events/EventIndex.vue')
      },
      {
        path: 'event/new',
        name: 'Create Event',
        component: () => import('../views/dashboard/events/EventCreate.vue')
      },
      {
        path: 'event/:id',
        name: 'Edit Event',
        component: () => import('../views/dashboard/events/EventEdit.vue')
      },
      // Businesses
      {
        path: 'business/cancel',
        name: 'Cancel Membership',
        component: () => import('../views/dashboard/business/CancelMembership.vue')
      },
      {
        path: 'business/membership/:id',
        name: 'Membership',
        component: () => import('../views/dashboard/business/Membership.vue')
      },
      {
        path: 'business/:id',
        name: 'Business Branch Edit',
        component: () => import('../views/dashboard/business/BusinessBranchEdit.vue')
      },
      // Quote Requests
      {
        path: '/quote-requests',
        name: 'Quote Requests',
        component: () =>
          import('../views/dashboard/trading-platform/QuoteRequestsIndex.vue')
      },
      {
        path: '/quote-request/:id',
        name: 'Quote Request Details',
        component: () =>
          import('../views/dashboard/trading-platform/QuoteRequestsDetails.vue')
      },
      // Find a Business
      {
        path: 'find-a-business',
        name: 'Find a Business',
        component: () =>
          import('../views/dashboard/FindABusiness/FindABusiness.vue')
      },
      // Trading Options
      {
        path: 'trading-options',
        name: 'Trading Options',
        component: () =>
          import('../views/dashboard/trading-options/TradingOptions.vue')
      },
      // Offers
      {
        path: '/offers',
        name: 'Offers',
        component: () => import('../views/dashboard/offers/OfferIndex.vue')
      },
      {
        path: '/offer/new',
        name: 'Create Offers',
        component: () => import('../views/dashboard/offers/OfferCreate.vue')
      },
      {
        path: '/offer/:slug',
        name: 'Edit Offer',
        component: () => import('../views/dashboard/offers/OfferEdit.vue')
      },
      // Shop
      {
        path: '/shop',
        name: 'Shop',
        component: () => import('../views/dashboard/shop/Shop.vue')
      },
      /* Admin */
      // Admin Memberships
      {
        path: '/admin/memberships/new',
        name: 'New Memberships',
        component: () =>
          import('../views/admin/membership/MembershipsNew.vue')
      },
      {
        path: '/admin/memberships/lapsed',
        name: 'Lapsed Memberships',
        component: () =>
          import('../views/admin/membership/MembershipsLapsed.vue')
      },
      {
        path: '/admin/memberships/cancellations',
        name: 'Cancel Membership Requests',
        component: () =>
          import('../views/admin/membership/MembershipsCancelled.vue')
      },
      {
        path: '/admin/memberships/express',
        name: 'Express Sign ups',
        component: () =>
          import('../views/admin/membership/MembershipsExpress.vue')
      },
      // Admin Content
      {
        path: '/admin/blogs',
        name: 'Admin Blogs',
        component: () =>
          import('../views/admin/content/AdminContentBlogs.vue')
      },
      {
        path: '/admin/offers',
        name: 'Offers',
        component: () =>
          import('../views/admin/content/AdminContentOffers.vue')
      },
      {
        path: '/admin/events',
        name: 'Events',
        component: () =>
          import('../views/admin/content/AdminContentEvents.vue')
      },
      // Admin Emails
      {
        path: '/admin/email/logged',
        name: 'Logged Emails',
        component: () => import('../views/admin/email/logged/LoggedEmailsIndex.vue')
      },
      {
        path: '/admin/email/logged/:id',
        name: 'Logged Email Detail',
        component: () => import('../views/admin/email/logged/LoggedEmailDetails.vue')
      },
      {
        path: '/admin/email/scheduled',
        name: 'Scheduled Emails',
        component: () => import('../views/admin/email/scheduled/ScheduledEmailsIndex.vue')
      },
      {
        path: '/admin/email/templates',
        name: 'Email Templates',
        component: () =>
          import('../views/admin/email/templates/EmailTemplates.vue')
      },
      {
        path: '/admin/email/templates/edit',
        name: 'Email Template Edit',
        component: () =>
          import('../views/admin/email/templates/EmailTemplateEdit.vue')
      },
      // Admin System
      {
        path: 'admin/zones',
        component: () => import('../views/admin/system/zone/AdminZoneIndex.vue')
      },
      {
        path: 'admin/zone/edit/:id',
        component: () => import('../views/admin/system/zone/AdminZoneEdit.vue')
      },
      {
        path: 'admin/categories',
        component: () =>
          import('../views/admin/system/category/AdminCategoryIndex.vue')
      },
      {
        path: 'admin/category/:id',
        component: () =>
          import('../views/admin/system/category/AdminCategoryDetail.vue')
      },
      // Admin Payment
      {
        path: 'admin/voucher',
        component: () => import('../views/admin/payment/Vouchers.vue')
      },
      /* Admin Database */
      {
        path: '/admin/database/quote-requests',
        name: 'Admin Quote Requests',
        component: () =>
          import('../views/admin/database/quote-request/AdminQuoteRequestIndex.vue')
      },
      {
        path: '/admin/database/quote-request/:id',
        name: 'Admin Quote Request Details',
        component: () =>
          import('../views/admin/database/quote-request/AdminQuoteRequestDetail.vue')
      },
      {
        path: '/admin/database/businesses',
        name: 'Admin Database Businesses',
        component: () =>
          import('../views/admin/database/business/AdminBusinesses.vue')
      },
      {
        path: 'admin/database/businesses/create',
        name: 'Admin Create Business',
        component: () => import('../views/admin/database/business/AdminBusinessCreate.vue')
      },
      {
        path: '/admin/database/users',
        name: 'Admin Database Users',
        component: () =>
          import('../views/admin/database/user/AdminDatabaseUsers.vue')
      },
      {
        path: '/admin/database/website',
        name: 'Admin Database Website',
        component: () =>
          import('../views/admin/database/website/WebsiteDatabase.vue')
      },
      {
        path: '/admin/database/feedback',
        name: 'Admin Database Feedback',
        component: () =>
          import('../views/admin/database/feedback/Feedback.vue')
      },
      {
        path: '/admin/database/newsletter',
        name: 'Admin Database Newsletter',
        component: () =>
          import('../views/admin/database/newsletter/NewsletterSubscriberDatabase.vue')
      },

      // Admin content approval

      // Website Editor
      {
        path: 'my-websites/preview/:id',
        name: 'Website Edit',
        component: () => import('../views/dashboard/my-website/MyWebsitePreview.vue')
      },

      // Demo
      {
        path: '/demo/ui',
        name: 'ui Demo',
        component: () => import('../views/demo/UIDemo.vue')
      },
      {
        path: '/demo/component',
        name: 'Component Demo',
        component: () => import('../views/demo/ComponentDemo.vue')
      }
    ]
    // End console page
  },
  // My Website Editor
  {
    path: '/my-websites/edit/:id',
    name: 'My Website Edit',
    component: () => import('../views/dashboard/my-website/MyWebsiteEdit.vue')
  },
  // Errors
  {
    path: '/error/under-maintenance',
    name: 'Under Maintenance',
    meta: {
      allowAnon: true
    },
    component: () => import('../views/error/Maintenance.vue')
  },
  {
    path: '/error/500',
    name: 'Server Error',
    meta: {
      allowAnon: true
    },
    component: () => import('../views/error/InternalServerError.vue')
  },
  {
    path: '/error/404',
    name: 'Not Found',
    meta: {
      allowAnon: true
    },
    component: () => import('../views/error/NotFound.vue')
  }
  // Default when page not found
  // {
  //   path: '*',
  //   redirect: '/error/404'
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
        // offset: { x: 0, y: 100 },
        // behavior: 'smooth'
      }
    }
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
