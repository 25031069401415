import {IHttpClient} from "../.."
import APIBase from "../APIBase"
import {ZoneModel} from "../../../models/api/admin"
import ZoneFilter from "../../../filters/ZoneFilter"
import ZoneSuggestionModel from "../../../models/api/admin/ZoneSuggestionModel"

class ZoneAPI extends APIBase {
    constructor(httpclient: IHttpClient) {
        super(httpclient)
    }

    getZone(zoneId: string): Promise<ZoneModel> {
        return this.GET({
            Path: `/zones/${zoneId}`
        })
    }

    getZones(zoneFilter: ZoneFilter): Promise<ZoneModel[]> {
        return this.GET({
            Path: `/zones`,
            QueryParams: zoneFilter.ToQueryParams()
        })
    }

    getZonesById(zoneIds: string[]): Promise<ZoneModel[]> {
        return this.GET({
            Path: `/zones`,
            QueryParams: {
                zoneIds: zoneIds.join(',')
            }
        })
    }

    Autocomplete(query: string, categoryIds: string[] | undefined = undefined): Promise<ZoneModel[]> {
        return this.GET({
            Path: '/zones/autocomplete',
            QueryParams: {
                q: query,
                categoryIds: categoryIds ? categoryIds.join(',') : ''
            },
            Anon: true
        })
    }

    Suggest(query: string, zoneIds: string[] | undefined = undefined): Promise<ZoneSuggestionModel | null> {
        return this.GET({
            Path: '/zones/suggest',
            QueryParams: {
                q: query,
                zoneIds: zoneIds ? zoneIds.join(',') : ''
            },
            Anon: true
        })
    }

    QueryZones(zoneIds: string[]): Promise<ZoneModel[]> {
        return this.GET({
            Path: `zones/${zoneIds}`,
            Anon: true
        })
    }

    GetAllZones(): Promise<ZoneModel[]> {
        return this.GET({
            Path: `zones/all`,
            Anon: true
        })
    }
}

export default ZoneAPI
