import {IHttpClient} from "../..";
import {CategoryModel, CategorySuggestionModel} from "../../../models/api/categorisation";
import APIBase from "../APIBase";
import BusinessBranchOrCategorySearchResult from '../../../models/api/search/BusinessBranchOrCategorySearchResult'

export default class CategoriesAPI extends APIBase {
    constructor(httpClient: IHttpClient) {
        super(httpClient);
    }

    Autocomplete(query: string, zoneIds: string[] | undefined = undefined): Promise<BusinessBranchOrCategorySearchResult[]> {
        return this.GET({
            Path: '/categories/autocomplete',
            QueryParams: {
                q: query,
                zoneIds: zoneIds ? zoneIds.join(',') : ''
            },
            Anon: true
        })
    }

    AutocompleteCategoryiesOnly(query: string, zoneIds: string[] | undefined = undefined): Promise<CategoryModel[]> {
        return this.GET({
            Path: '/categories/autocomplete-category',
            QueryParams: {
                q: query,
                zoneIds: zoneIds ? zoneIds.join(',') : ''
            },
            Anon: true
        })
    }

    Suggest(query: string, zoneIds: string[] | undefined = undefined): Promise<CategorySuggestionModel | null> {
        return this.GET({
            Path: '/categories/suggest',
            QueryParams: {
                q: query,
                zoneIds: zoneIds ? zoneIds.join(',') : ''
            },
            Anon: true
        })
    }

    GetPopular(numResults: number): Promise<CategoryModel[]> {
        return this.GET({
            Path: '/categories/popular',
            QueryParams: {
                numResults: numResults.toString()
            },
            Anon: true
        })
    }

    Query(catIds: string[]): Promise<BusinessBranchOrCategorySearchResult[]> {
        return this.GET({
            Path: `/categories/${catIds}`,
            Anon: true
        })
    }
}
