import {IHttpClient} from "../../.."
import APIBase from "../../APIBase"
import WebsiteFilter from '../../../../filters/WebsiteFilter'
import MyWebsiteV3Model from '../../../../models/api/my-websites/MyWebsiteV3Model'

class AdminWebsiteAPI extends APIBase {
    constructor(httpClient: IHttpClient) {
        super(httpClient);
    }

    Filter(filter: WebsiteFilter): Promise<MyWebsiteV3Model> {
        return this.GET({
            Path: `/admin/my-websites/filter`,
            QueryParams: filter.ToQueryParams()
        })
    }
}

export default AdminWebsiteAPI
