
import { Vue, Component, Prop } from 'vue-property-decorator'
import Loader from '../status/Loader.vue'

@Component({
  components: {
    Loader
  }
})
// Elements including icons will be automatically spaced
export default class Button extends Vue {
  @Prop({ default: 'button' })
  private readonly type!: 'button' | 'submit' | 'reset';

  @Prop({ default: 'default' })
private readonly scheme!: 'default' | 'primary' | 'secondary' | 'tertiary' | 'subtle' | 'info' | 'success' | 'warning' | 'danger' | 'aesthetic' | 'purple' | 'none'

  @Prop({ default: 'default' })
  private readonly variant!: 'default' | 'link' | 'round';

  @Prop({ default: 'default' })
  private readonly spacing!: 'default' | 'compact' | 'none' | 'full';

  @Prop({ default: false })
  private readonly isLoading!: boolean;

  @Prop({ default: false })
  private readonly isDisabled!: boolean;

  @Prop({ default: false })
  private readonly isFullWidth!: boolean;

  @Prop({ default: false })
  private readonly isFullHeight!: boolean;
}
