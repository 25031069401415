import {IHttpClient} from "../..";
import ContactSubmission from '../../../models/api/contact/ContactSubmission'
import APIBase from "../APIBase";

export default class ContactAPI extends APIBase {
    constructor(httpClient: IHttpClient) {
        super(httpClient);
    }

    SubmitContactForm(sub: ContactSubmission): Promise<boolean> {
        return this.POST({
            Path: '/contact',
            Body: sub,
            Anon: true
        })
    }
}
