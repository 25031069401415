import UserFilter from '../../../../filters/UserFilter'
import {PagedResultSet} from '../../../../models/api/common/PagedResultSet'
import AdminUserModel from '../../../../models/api/users/AdminUserModel'
import AdminUserCreateDto from '../../../../models/api/admin/users/AdminUserCreateDto'
import UserModel from '../../../../models/api/users/UserModel'
import IHttpClient from '../../../httpclient/IHttpClient'
import APIBase from '../../APIBase'

class AdminUsersAPI extends APIBase {
    constructor(httpClient: IHttpClient) {
        super(httpClient);
    }

    Create(dto: AdminUserCreateDto): Promise<UserModel> {
        return this.POST({
            Path: '/admin/users',
            Body: dto
        })
    }

    Search(filter: UserFilter): Promise<PagedResultSet<AdminUserModel>> {
        return this.GET({
            Path: '/admin/users',
            QueryParams: filter.ToQueryParams()
        })
    }

    ExportUsers(filter: UserFilter): Promise<Blob> {
        return this.GET({
            Path: '/admin/users/export',
            QueryParams: filter.ToQueryParams()
        })
    }
}

export default AdminUsersAPI;
