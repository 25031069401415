import {IHttpClient} from "../../.."
import ScheduledEmailFilter from '../../../../filters/EmailScheduledFilter'
import EmailTemplate from '../../../../models/api/admin/email-templates/EmailTemplate'
import EmailTemplateRevision from '../../../../models/api/admin/email-templates/EmailTemplateRevision'
import EmailTemplateRevisionContent from '../../../../models/api/admin/email-templates/EmailTemplateRevisionContent'
import EmailTemplateRevisionUpdate from '../../../../models/api/admin/email-templates/EmailTemplateRevisionUpdate'
import EmailTemplateRevisionCreate from '../../../../models/api/admin/email-templates/EmailTemplateRevisionCreate'
import EmailScheduledModel from '../../../../models/api/admin/emails/scheduled/EmailScheduledModel'
import {PagedResultSet} from "../../../../models/api/common"
import APIBase from "../../APIBase"
import EmailLoggedModel from '../../../../models/api/admin/emails/logged/EmailLoggedModel'
import EmailLoggedFilter from '../../../../filters/EmailLoggedFilter'
import SendTestEmailRequest from '../../../../models/api/admin/email-templates/SendTestEmailRequest'
import EmailTemplateCreate from '../../../../models/api/admin/email-templates/EmailTemplateCreate'
import EmailTemplateSendDto from "../../../../models/api/emails/EmailTemplateSendDto";
import EmailScheduledUpdateDto from '../../../../models/api/admin/emails/scheduled/EmailScheduledUpdateDto'

export default class AdminEmailAPI extends APIBase {
    constructor(httpClient: IHttpClient) {
        super(httpClient);
    }

    // Emails Logged

    GetLoggedEmail(id: string): Promise<EmailLoggedModel> {
        return this.GET({
            Path: `/admin/email/${id}`
        })
    }

    GetLoggedEmails(filter: EmailLoggedFilter): Promise<PagedResultSet<EmailLoggedModel>> {
        return this.GET({
            Path: `/admin/email/`,
            QueryParams: filter.ToQueryParams()
        })
    }

    // Emails Scheduled

    GetScheduledEmail(id: string): Promise<EmailScheduledModel> {
        return this.GET({
            Path: `/admin/email/scheduled/${id}`
        })
    }

    GetScheduledEmails(filter: ScheduledEmailFilter): Promise<PagedResultSet<EmailScheduledModel>> {
        return this.GET({
            Path: `/admin/email/scheduled`,
            QueryParams: filter.ToQueryParams()
        })
    }

    EmailScheduledSend(id: string): Promise<boolean> {
        return this.PATCH({
            Path: `/admin/email/scheduled/send/${id}`
        })
    }

    EmailScheduledUpdate(id: string, dto: EmailScheduledUpdateDto): Promise<boolean> {
        return this.PATCH({
            Path: `/admin/email/scheduled/${id}`,
            Body: dto
        })
    }

    // Email Templates

    GetEmailTemplate(id: string): Promise<EmailTemplate> {
        return this.GET({
            Path: `/admin/email/template/${id}`
        })
    }

    GetEmailTemplates(): Promise<EmailTemplate[]> {
        return this.GET({
            Path: '/admin/email/template',
        })
    }

    GetRevisions(emailTemplateId: string): Promise<EmailTemplateRevision[]> {
        return this.GET({
            Path: `/admin/email/template/${emailTemplateId}/revision`
        })
    }

    GetMasterEmailTemplateRevision(): Promise<EmailTemplateRevision> {
        return this.GET({
            Path: `/admin/email/template/master`
        })
    }

    GetRevisionContent(revisionId: string): Promise<EmailTemplateRevisionContent> {
        return this.GET({
            Path: `/admin/email/template/revision/${revisionId}`
        })
    }

    RevisionUpdate(revisionId: string, revisionContent: EmailTemplateRevisionUpdate): Promise<EmailTemplateRevision> {
        return this.PATCH({
            Path: `/admin/email/template/revision/${revisionId}`,
            Body: revisionContent
        })
    }

    RevisionCreate(templateId: string, revisionCreate: EmailTemplateRevisionCreate): Promise<EmailTemplateRevision> {
        return this.POST({
            Path: `/admin/email/template/${templateId}/revision`,
            Body: revisionCreate
        })
    }

    RevisionChangeCurrent(tempalteId: string, revisionId: string): Promise<boolean> {
        return this.PATCH({
            Path: `/admin/email/template/${tempalteId}/revision/${revisionId}/current`
        })
    }

    SendTest(request: SendTestEmailRequest): Promise<EmailTemplateRevision> {
        return this.POST({
            Path: '/admin/email/template/send-test',
            Body: request
        })
    }

    CreateTemplate(dto: EmailTemplateCreate): Promise<EmailTemplate> {
        return this.POST({
            Path: '/admin/email/template/new',
            Body: dto
        })
    }

    TempalteDelete(id: string): Promise<boolean> {
        return this.DELETE({
            Path: `/admin/email/template/${id}`
        })
    }

    EmailTemplateSend(id: string, dto: EmailTemplateSendDto): Promise<EmailTemplate> {
        return this.POST({
            Path: `/admin/email/template/${id}/send`,
            Body: dto
        })
    }
}
