import StateModule from './StateModule'

interface RegisterState {
  Page: number;
}

class RegisterModule implements StateModule<RegisterState> {
  namespaced = true

  state = {
    Page: 3
  }
}

export { RegisterModule, RegisterState }
