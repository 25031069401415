enum ServiceType {
    HttpClient = 'httpClient',
    API = 'api',
    BrowserStorage = 'browserStorage',
    Head = 'head',
    CredentialManager = 'credentialManager',
    EventBus = 'eventBus',
    FormManager = 'formManager',
    CrossDomainCredentialManager = 'CrossDomainCredentialManager'
}

export default ServiceType;
