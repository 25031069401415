import IHttpClient from "../httpclient/IHttpClient";
import {Service} from "../IService";
import ServiceType from "../ServiceTypes";
import IAPI from "./IAPI";
import AdminAPI from "./types/admin/AdminAPI";
import AnalyticEventAPI from './types/AnayticsEventAPI'
import VoucherAPI from './types/admin/VoucherAPI'
import BlogAPI from "./types/BlogAPI";
import BusinessAPI from "./types/BusinessAPI";
import CategoriesAPI from "./types/CategoriesAPI";
import EventAPI from "./types/EventAPI";
import FileAPI from "./types/FileAPI";
import FormAPI from './types/FormAPI'
import MyWebsiteAPI from './types/MyWebsiteAPI'
import PaymentAPI from "./types/PaymentAPI";
import ProductAPI from './types/ProductAPI'
import QuotesAPI from "./types/QuotesAPI"
import SignupAPI from "./types/SignupAPI";
import TelemetryAPI from "./types/TelemetryAPI";
import UsersAPI from "./types/UsersAPI";
import ZoneAPI from "./types/ZoneAPI"
import EmailAPI from "./types/EmailAPI"
import ContactAPI from './types/ContactAPI'
import OfferAPI from './types/OfferAPI'
import FetchClient from '../httpclient/providers/FetchClient'
import MyWebsiteRegistrationAPI from "./types/MyWebsiteRegistrationAPI";
import EmailNewsletterSubscriptionController from "./types/EmailNewsletterSubscriptionController";

class API implements Service, IAPI {
    Type: ServiceType = ServiceType.API;
    Id = 'API';
    // private HttpClient: IHttpClient = ServiceManager.Require(ServiceType.HttpClient) as IHttpClient;
    HttpClient: IHttpClient;
    Admin: AdminAPI;
    AnalyticEvent: AnalyticEventAPI;
    Blogs: BlogAPI;
    Businesses: BusinessAPI;
    Categories: CategoriesAPI;
    Contact: ContactAPI;
    Emails: EmailAPI;
    EmailNewsletterSubscriptions: EmailNewsletterSubscriptionController;
    Events: EventAPI;
    Files: FileAPI;
    Form: FormAPI;
    MyWebsites: MyWebsiteAPI;
    MyWebsiteRegistration: MyWebsiteRegistrationAPI;
    Offers: OfferAPI;
    Payment: PaymentAPI;
    Products: ProductAPI;
    Quotes: QuotesAPI;
    Signup: SignupAPI;
    Zones: ZoneAPI;
    Telemetry: TelemetryAPI;
    Users: UsersAPI;
    Vouchers: VoucherAPI;

    constructor() {
        this.HttpClient = new FetchClient();
        this.Admin = new AdminAPI(this.HttpClient);
        this.AnalyticEvent = new AnalyticEventAPI(this.HttpClient)
        this.Blogs = new BlogAPI(this.HttpClient);
        this.Businesses = new BusinessAPI(this.HttpClient);
        this.Categories = new CategoriesAPI(this.HttpClient);
        this.Contact = new ContactAPI(this.HttpClient);
        this.Emails = new EmailAPI(this.HttpClient);
        this.EmailNewsletterSubscriptions = new EmailNewsletterSubscriptionController(this.HttpClient);
        this.Events = new EventAPI(this.HttpClient);
        this.Files = new FileAPI(this.HttpClient);
        this.Form = new FormAPI(this.HttpClient);
        this.MyWebsites = new MyWebsiteAPI(this.HttpClient);
        this.MyWebsiteRegistration = new MyWebsiteRegistrationAPI(this.HttpClient);
        this.Offers = new OfferAPI(this.HttpClient);
        this.Payment = new PaymentAPI(this.HttpClient);
        this.Products = new ProductAPI(this.HttpClient);
        this.Quotes = new QuotesAPI(this.HttpClient);
        this.Signup = new SignupAPI(this.HttpClient);
        this.Zones = new ZoneAPI(this.HttpClient);
        this.Telemetry = new TelemetryAPI(this.HttpClient);
        this.Users = new UsersAPI(this.HttpClient);
        this.Vouchers = new VoucherAPI(this.HttpClient);
    }
}


export default API;
