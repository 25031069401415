import {IHttpClient} from "../../.."
import StripeProduct from '../../../../models/api/admin/stripe/products/StripeProduct'
import StripeProductCreate from '../../../../models/api/admin/stripe/products/StripeProductCreate'
import StripeProductUpdate from '../../../../models/api/admin/stripe/products/StripeProductUpdate'
import APIBase from "../../APIBase"
import ProductFilter from '../../../../filters/ProductFilter'
import StripePrice from '../../../../models/api/admin/stripe/price/StripePrice'
import PriceFilter from '../../../../filters/PriceFilter'
import StripePriceCreate from '../../../../models/api/admin/stripe/price/StripePriceCreate'

class AdminProductAPI extends APIBase {
    constructor(httpClient: IHttpClient) {
        super(httpClient);
    }

    // Products
    GetProduct(productId: string): Promise<StripeProduct> {
        return this.GET({
            Path: `/admin/stripe/product/${productId}`
        })
    }

    GetProducts(filter: ProductFilter): Promise<StripeProduct[]> {
        return this.GET({
            Path: `/admin/stripe/product/`,
            QueryParams: filter.ToQueryParams()
        })
    }

    CreateProduct(productCreate: StripeProductCreate): Promise<StripeProduct> {
        return this.POST({
            Path: '/admin/stripe/product',
            Body: productCreate
        })
    }

    UpdateProduct(productId: string, productUpdate: StripeProductUpdate): Promise<StripeProduct> {
        return this.PATCH({
            Path: `/admin/stripe/product/${productId}`,
            Body: productUpdate
        })
    }

    // End Products
    // Prices
    GetPrices(productId: string, filter: PriceFilter): Promise<StripePrice[]> {
        return this.GET({
            Path: `/admin/stripe/${productId}/price`,
            QueryParams: filter.ToQueryParams()
        })
    }

    CreatePrice(productId: string, priceCreate: StripePriceCreate): Promise<StripePrice> {
        return this.PATCH({
            Path: `/admin/stripe/${productId}/price`,
            Body: priceCreate
        })
    }

    // End Prices

}

export default AdminProductAPI
