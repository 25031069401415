import StateModule from './StateModule'
import { CategoryModel } from 'tradingmate-modules/src/models/api/categorisation'
import { ZoneModel } from 'tradingmate-modules/src/models/api/admin'

interface SearchState {
  Categories: CategoryModel[] | null;
  Zones: ZoneModel[] | null;
}

class SearchModule implements StateModule<SearchState> {
  namespaced = true

  state = {
    Categories: null,
    Zones: null
  }

  mutations = {
    setCategories (state: SearchState, cats: CategoryModel[]): void {
      state.Categories = cats
    },
    setZones (state: SearchState, zones: ZoneModel[]): void {
      state.Zones = zones
    }
  }
}

export { SearchModule, SearchState }
