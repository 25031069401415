import {IHttpClient, Services} from "../.."
import APIBase from "../APIBase"
import CreateFormSubmissionModel from '../../../models/api/forms/CreateFormSubmissionModel'
import FormSubmissionModel from '../../../models/api/forms/FormSubmissionModel'
import {GetFileModel} from '../../../models/api/files'
import UpsertFormFieldValue from '../../../models/api/forms/UpsertFormFieldValue'
import FormFieldValueModel from '../../../models/api/forms/FormFieldValueModel'
import FormManager from '../../formManager/FormMananger'
import FormSubmissionTokenModel from '../../../models/api/auth/forms/FormSubmissionTokenModel'

class FormAPI extends APIBase {
    protected formManager: FormManager;

    constructor(httpclient: IHttpClient) {
        super(httpclient)
        this.formManager = Services.FormManager;
    }

    getFormHeader(): Record<string, string> | null {
        const formToken = this.formManager.GetFormSubmissionToken();
        if (formToken === null) return null;
        return {'TM-FormToken': formToken.Token}
    }

    CreateFormSubmission(model: CreateFormSubmissionModel): Promise<FormSubmissionTokenModel> {
        return this.POST({
            Path: `/forms/submissions`,
            Body: model,
            Anon: true
        })
    }

    GetFormSubmission(formSubmissionId: string): Promise<FormSubmissionTokenModel> {
        const formHeader = this.getFormHeader();
        if (formHeader === null) return Promise.reject('No form token available')

        return this.GET({
            Path: `/forms/submissions/${formSubmissionId}`,
            Headers: formHeader
        })
    }

    GetFormSubmissionFiles(formSubmissionId: string): Promise<GetFileModel[]> {
        const formHeader = this.getFormHeader();
        if (formHeader === null) return Promise.reject('No form token available')

        return this.GET({
            Path: `/forms/submissions/${formSubmissionId}/files`,
            Headers: formHeader
        })
    }

    UpdateFormSubmissionState(formSubmissionId: string, formFieldValues: UpsertFormFieldValue[]): Promise<FormFieldValueModel[]> {
        const formHeader = this.getFormHeader();
        if (formHeader === null) return Promise.reject('No form token available')

        return this.POST({
            Path: `/forms/submissions/${formSubmissionId}/state`,
            Body: formFieldValues,
            Headers: formHeader
        })
    }

    GetFormSubmissionState(formSubmissionId: string): Promise<FormFieldValueModel[]> {
        const formHeader = this.getFormHeader();
        if (formHeader === null) return Promise.reject('No form token available')

        return this.GET({
            Path: `/forms/submissions/${formSubmissionId}/state`,
            Headers: formHeader
        })
    }

    SubmitFormSubmission(formSubmissionId: string): Promise<FormSubmissionModel> {
        const formHeader = this.getFormHeader();
        if (formHeader === null) return Promise.reject('No form token available')

        return this.GET({
            Path: `/forms/submissions/${formSubmissionId}/submit`,
            Headers: formHeader
        })
    }
}

export default FormAPI
