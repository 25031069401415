import {IHttpClient} from "../../..";
import CreateVoucherEffectModel from '../../../../models/api/admin/vouchers/CreateVoucherEffectModel'
import CreateVoucherModel from '../../../../models/api/admin/vouchers/CreateVoucherModel'
import VoucherEffect from '../../../../models/api/admin/vouchers/VoucherEffect'
import VoucherModel from '../../../../models/api/admin/vouchers/VoucherModel'
import APIBase from "../../APIBase";

class VoucherAPI extends APIBase {
    constructor(httpClient: IHttpClient) {
        super(httpClient);
    }

    ReadVouchers(): Promise<VoucherModel[]> {
        return this.GET({Path: '/admin/vouchers'})
    }

    CreateVoucher(createVoucher: CreateVoucherModel): Promise<VoucherModel> {
        return this.POST({Path: '/admin/vouchers', Body: createVoucher})
    }

    RemoveVoucher(voucherId: string): Promise<VoucherModel> {
        return this.DELETE({Path: `/admin/vouchers/${voucherId}`})
    }

    // Effects
    CreateEffect(createEffect: CreateVoucherEffectModel): Promise<VoucherEffect> {
        return this.POST({Path: '/admin/vouchers/effects', Body: createEffect})
    }
}

export default VoucherAPI
