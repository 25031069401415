enum BusinessBranchStatus {
    None,
    Active,
    Inactive,
    Deleted,
    Draft,
    Abandoned,
    Express,
    WantingCancel = 7,
    Cancelled = 8,
    Lasped = 9,
    AdminAccount = 10
}

export default BusinessBranchStatus
