enum LogLevel {
    Debug,
    Info,
    Warn,
    Err,
    Critical
}

class Logger {
    static LogLevel: LogLevel = LogLevel.Debug;

    static SetLevel(level: LogLevel): void {
        this.LogLevel = level;
        this.Log(LogLevel.Debug, 'Set Log Level to ' + level);
    }

    static Log(level: LogLevel, scope: string, ...args: any[]): void {
        if (this.LogLevel > level) return;

        switch (level) {
            case LogLevel.Debug:
                console.debug(`[${scope}]`, ...args);
                break;
            case LogLevel.Info:
                console.log(`[${scope}]`, ...args);
                break;
            case LogLevel.Warn:
                console.warn(`[${scope}]`, ...args);
                break;
            case LogLevel.Critical:
            case LogLevel.Err:
                console.error(`[${scope}]`, ...args);
                break;
        }
    }
}

export {LogLevel, Logger}
