import StateModule from './StateModule'

interface UIState {
  theme: 'light' | 'dark';
  navOpen: boolean;
  loading: boolean;
}

class UIModule implements StateModule<UIState> {
  namespaced = true;

  state: UIState = {
    theme: 'light',
    navOpen: false,
    loading: false
  }

  mutations = {
    setTheme (state: UIState, theme: 'light' | 'dark'): void {
      state.theme = theme
    },
    toggleNav (state: UIState): void {
      state.navOpen = !state.navOpen
    },
    setLoading (state: UIState, loading: boolean): void {
      state.loading = loading
    }
  }
}

export { UIModule, UIState }
