import {IHttpClient} from "../../..";
import ZoneFilter from '../../../../filters/ZoneFilter'
import {
    AddPostcodeInZoneModel,
    CreateZoneModel,
    PostcodeInZoneModel,
    UpdateZoneModel,
    ZoneModel
} from "../../../../models/api/admin";
import AdminZoneModel from '../../../../models/api/admin/zones/AdminZoneModel'
import AdminMovePostcodeModel from '../../../../models/api/admin/zones/AdminMovePostcodeModel'
import APIBase from "../../APIBase";

class AdminZonesAPI extends APIBase {
    constructor(httpClient: IHttpClient) {
        super(httpClient);
    }

    GetZone(id: string): Promise<AdminZoneModel> {
        return this.GET({Path: `/admin/zones/${id}`})
    }

    GetZones(filter: ZoneFilter): Promise<AdminZoneModel[]> {
        return this.GET({
            Path: '/admin/zones/',
            QueryParams: filter.ToQueryParams()
        })
    }

    MovePostcode(fromId: string, model: AdminMovePostcodeModel): Promise<PostcodeInZoneModel> {
        return this.PATCH({
            Path: `/admin/zones/${fromId}/postcodes`,
            Body: model
        })
    }

    UpdateZone(id: string, model: UpdateZoneModel): Promise<ZoneModel> {
        return this.PATCH({Path: `/admin/zones/${id}`, Body: model})
    }

    CreateZone(model: CreateZoneModel): Promise<ZoneModel> {
        return this.POST({Path: '/admin/zones', Body: model})
    }

    DeleteZone(id: string): Promise<boolean> {
        return this.DELETE({Path: `/admin/zones/${id}`})
    }

    GetPostcodes(id: string): Promise<PostcodeInZoneModel[]> {
        return this.GET({Path: `/admin/zones/${id}/postcodes`})
    }

    AddPostcode(id: string, postcode: AddPostcodeInZoneModel): Promise<PostcodeInZoneModel> {
        return this.POST({Path: `/admin/zones/${id}/postcodes`, Body: postcode})
    }

    DeletePostcode(id: string, postcode: string): Promise<boolean> {
        return this.DELETE({Path: `/admin/zones/${id}/postcodes/${postcode}`})
    }
}

export default AdminZonesAPI
