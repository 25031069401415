import Vue from 'vue'
import Vuex from 'vuex'
import { ENVModule, ENVState } from './modules/ENVModule'
import { IdentityModule, IdentityState } from './modules/IdentityModule'
import { UIModule, UIState } from './modules/UIModule'
import { SearchModule, SearchState } from '@/store/modules/Search'
import { RegisterModule, RegisterState } from './modules/RegisterModule'

Vue.use(Vuex)

interface State {
  ui: UIState;
  env: ENVState;
  identity: IdentityState;
  search: SearchState;
  register: RegisterState;
}

export default new Vuex.Store({
  modules: {
    ui: new UIModule(),
    env: new ENVModule(),
    identity: new IdentityModule(),
    search: new SearchModule(),
    register: new RegisterModule()
  },
  state: {} as State
})
