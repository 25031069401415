import IHttpClient from '../../httpclient/IHttpClient'
import APIBase from '../APIBase'
import EmailNewsletterSubscriptionReadDto
    from "../../../models/api/email-newsletter-subscriptions/EmailNewsletterSubscriptionReadDto";
import EmailNewsletterSubscriptionCreateDto
    from "../../../models/api/email-newsletter-subscriptions/EmailNewsletterSubscriptionCreateDto";
import EmailNewsletterSubscriptionUpdateDto
    from "../../../models/api/email-newsletter-subscriptions/EmailNewsletterSubscriptionUpdateDto";
import EmailNewsletterSubscriptionFilter from '../../../filters/EmailNewsletterSubscription'
import { PagedResultSet } from '../../../models/api/common'

class EmailNewsletterSubscriptionController extends APIBase {
    constructor(httpClient: IHttpClient) {
        super(httpClient);
    }

    Create (createDto: EmailNewsletterSubscriptionCreateDto): Promise<EmailNewsletterSubscriptionReadDto> {
        return this.POST({
            Path: `/newsletter`,
            Body: createDto,
            Anon: true
        })
    }

    Update (updateDto: EmailNewsletterSubscriptionUpdateDto): Promise<EmailNewsletterSubscriptionReadDto> {
        return this.PATCH({
            Path: `/newsletter`,
            Body: updateDto,
            Anon: true
        })
    }

    Filter (filter: EmailNewsletterSubscriptionFilter): Promise<PagedResultSet<EmailNewsletterSubscriptionReadDto>> {
        return this.GET({
            Path: '/newsletter',
            QueryParams: filter.ToQueryParams()
        })
    }

    Delete (email: string): Promise<void> {
        return this.DELETE({
            Path: `/newsletter/${email}`
        })
    }

    Export (filter: EmailNewsletterSubscriptionFilter): Promise<Blob> {
        return this.GET({
            Path: '/newsletter/export',
            QueryParams: filter.ToQueryParams()
        })
    }

}

export default EmailNewsletterSubscriptionController
