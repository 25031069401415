import MyWebsiteV3Model from './../../../models/api/my-websites/MyWebsiteV3Model'
import MyWebsiteDomainCheckResponce from '../../../models/api/my-websites/MyWebsiteDomainCheckResponce'
import IHttpClient from '../../httpclient/IHttpClient'
import APIBase from '../APIBase'
import MyWebsiteRegisterRequest from '../../../models/api/my-websites/MyWebsiteRegisterRequest'
import MYWebsiteRegisterResponce from '../../../models/api/my-websites/MYWebsiteRegisterResponce'

class MyWebsiteAPI extends APIBase {
    constructor(httpClient: IHttpClient) {
        super(httpClient);
    }

    CheckDomainAvailability(domain: string): Promise<MyWebsiteDomainCheckResponce> {
        return this.GET({
            Path: `/my-websites/domain/${domain}`,
            Anon: true
        })
    }

    GetWebsitePreview(branchId: string): Promise<string> {
        return this.GET({
            Path: `/my-websites/preview/${branchId}`
        })
    }

    GetMyWebsite(branchId: string): Promise<MyWebsiteV3Model> {
        return this.GET({
            Path: `/my-websites/${branchId}`,
        })
    }

    UpdateWebsite(websiteId: string, website: MyWebsiteV3Model): Promise<undefined> {
        return this.POST({
            Path: `/my-websites/${websiteId}`,
            Body: website
        })
    }

    RegisterWebsite(request: MyWebsiteRegisterRequest): Promise<MYWebsiteRegisterResponce> {
        return this.POST({
            Path: `/my-websites/register/`,
            Body: request
        })
    }
}

export default MyWebsiteAPI
