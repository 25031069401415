import {IHttpClient} from "../..";
import EventFilter from "../../../filters/EventFilter";
import {CreateEventModel, EventModel, UpdateEventModel} from "../../../models/api/events";
import APIBase from "../APIBase";
import {PagedResultSet} from "../../../models/api/common";


class EventAPI extends APIBase {
    constructor(httpclient: IHttpClient) {
        super(httpclient)
    }

    GetEvent(eventId: string): Promise<EventModel> {
        return this.GET({
            Path: `/events/${eventId}`
        })
    }

    GetAllEvents(filter: EventFilter): Promise<PagedResultSet<EventModel>> {
        return this.GET({
            Path: '/events/all',
            QueryParams: filter.ToQueryParams()
        })
    }

    GetEventBySlug(slug: string): Promise<EventModel> {
        return this.GET({
            Anon: true,
            Path: `/events/slug/${slug}`
        })
    }

    NextEvent(eventId: string): Promise<EventModel> {
        return this.GET({
            Path: `/events/${eventId}/next`
        })
    }

    PreviousEvent(eventId: string): Promise<EventModel> {
        return this.GET({
            Path: `/events/${eventId}/next`
        })
    }

    GetEvents(filter: EventFilter): Promise<PagedResultSet<EventModel>> {
        return this.GET({
            Path: '/events',
            QueryParams: filter.ToQueryParams()
        })
    }

    GetTmEvents(): Promise<EventModel[]> {
        return this.GET({
            Path: '/events/tm',
            Anon: true
        })
    }


    GetEventsForPublic(filter: EventFilter): Promise<PagedResultSet<EventModel>> {
        return this.GET({
            Anon: true,
            Path: '/events',
            QueryParams: filter.ToQueryParams()
        })
    }

    CreateEvent(branchId: string, eventModel: CreateEventModel): Promise<EventModel> {
        return this.POST({
            Path: `/events/${branchId}`,
            Body: eventModel
        })
    }

    UpdateEvent(eventId: string, eventModel: UpdateEventModel): Promise<EventModel> {
        return this.PATCH({
            Path: `/events/${eventId}`,
            Body: eventModel
        })
    }

    DeleteEvent(eventId: string): Promise<void> {
        return this.DELETE({
            Path: `/events/${eventId}`
        })
    }
}

export default EventAPI
