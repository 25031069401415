
import { Mixins, Vue, Prop } from 'vue-property-decorator'

export default class ContentContainer extends Mixins(Vue) {
  @Prop({ })
  private readonly background!: string

  @Prop()
  private readonly rounded!: boolean
}
