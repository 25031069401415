import AppConfig from './config'

import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'

import { Bootstrapper } from 'tradingmate-modules/src/startup'

import './components/global'
import { Services } from 'tradingmate-modules/src/services'
// import { Logger, LogLevel } from 'tradingmate-modules/src/debug/Logger'
import { BusinessBranchStatus } from '../../tradingmate-modules/src/models/api/businesses'

Vue.config.productionTip = false

Bootstrapper.Initialise(AppConfig).then(() => {
  // store.commit('ui/bootstrap')

  router.beforeEach((to, from, next) => {
    if (to.meta?.allowAnon)
    {
      console.log('allow anon')
      next()
      // eslint-disable-next-line no-useless-return
      return
    }
    else if (store.state.identity.LoggedIn) {
      // User is logged in, or the destination allows anon requests, allow them to proceed
      if (store.state.identity.BusinessBranch?.Status === BusinessBranchStatus.Active ||
        store.state.identity.BusinessBranch?.Status === BusinessBranchStatus.Express ||
        store.state.identity.BusinessBranch?.Status === BusinessBranchStatus.WantingCancel ||
        store.state.identity.BusinessBranch?.Status === BusinessBranchStatus.AdminAccount) {
        console.log('logged in')
        next()
        // eslint-disable-next-line no-useless-return
        return
      }
      else {
        // next()
        // return
        console.log('logged out')
        console.log(to.name)
        if (to.name === 'Logout')
        {
          next()
          // eslint-disable-next-line no-useless-return
          return
        }
        else if (to.name === 'Membership')
        {
          next()
          // eslint-disable-next-line no-useless-return
          return
        }
        else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          next({ name: 'Membership', params: { id: store.state.identity.BusinessBranch?.BusinessBranchId } })
          // eslint-disable-next-line no-useless-return
          return
        }
      }

      // console.log(to)
      // name: 'Membership',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // params: { id: store.state.identity.BusinessBranch?.BusinessBranchId }
      // })
      // return
      // router.push(`business/membership/${store.state.identity.BusinessBranch?.BusinessBranchId}`)
    }
    else {
      // Check to see if we have a session
      store.commit('ui/setLoading', true)

      Services.API.Users.Me()
        .then(me => {
          store.commit('identity/setUser', me)

          return Services.API.Users.GetBusinessBranches()
        })
        .then(branches => {
          store.commit('identity/setBranch', branches[0])

          store.commit('ui/setLoading', false)

          next()
        })
        .catch(() => {
          store.commit('ui/setLoading', false)

          console.log('next to fullpath')
          next({
            path: '/account/login',
            query: {
              next: to.fullPath
            }
          })
        })
    }
  })

  const mountApp = (success: boolean) => {
    const app = new Vue({
      router: success ? router : undefined,
      store,
      render: h => h(App)
    })

    app.$mount('#app')
  }

  // Services.API.Telemetry.Ping()
  //   .then(success => {
  //     store.commit('env/setErrored', !success)

  //     mountApp(success)
  //   })
  //   .catch(ex => {
  //     // Logger.Log(LogLevel.Critical, ex)

  //     // For now ignore the API ping
  //     // store.commit('env/setErrored', true)
  //     store.commit('env/setErrored', false)

  //     // mountApp(false)
  //     mountApp(true)
  //   })

  mountApp(true)
})
