import IHttpClient from '../../httpclient/IHttpClient'
import APIBase from '../APIBase'
import MyWebsiteRegistrationReadDto from "../../../models/api/my-websites/registration/MyWebsiteRegistrationReadDto";

class MyWebsiteRegistrationAPI extends APIBase {
    constructor(httpClient: IHttpClient) {
        super(httpClient);
    }

    FindMyWebsiteRegistration(id: string): Promise<MyWebsiteRegistrationReadDto> {
        return this.GET({
            Path: `/my-website-registration/${id}`
        })
    }

    QueryStartedWebsiteRegistrations(): Promise<MyWebsiteRegistrationReadDto[]> {
        return this.GET({
            Path: `/my-website-registration`
        })
    }

    ManualRestart(id: string): Promise<MyWebsiteRegistrationReadDto> {
        return this.PATCH({
            Path: `/my-website-registration/${id}`,
        })
    }

}

export default MyWebsiteRegistrationAPI
