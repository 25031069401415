import APIBase from '../../APIBase'
import {IHttpClient} from "../../.."
import {OfferReadDto} from '../../../../models/api/offers'
import {EventModel} from '../../../../models/api/events'
import {BlogModel} from '../../../../models/api/blogs'


class AdminContentAPI extends APIBase {
    constructor(httpClient: IHttpClient) {
        super(httpClient);
    }

    GetBlogsNotSeen(): Promise<BlogModel[]> {
        return this.GET({Path: '/admin/blogs/'})
    }

    MarkSeenBlog(id: string): Promise<BlogModel> {
        return this.PATCH({Path: `/admin/blogs/${id}`})
    }

    GetEventsNotSeen(): Promise<EventModel[]> {
        return this.GET({Path: '/admin/events/'})
    }

    MarkSeenEvent(id: string): Promise<EventModel> {
        return this.PATCH({Path: `/admin/events/${id}`})
    }

    GetOffersNotSeen(): Promise<OfferReadDto[]> {
        return this.GET({Path: '/admin/offers'})
    }

    MarkSeenOffer(id: string): Promise<OfferReadDto> {
        return this.PATCH({Path: `/admin/offers/${id}`})
    }

}

export default AdminContentAPI
