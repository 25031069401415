import APIBase from '../../APIBase'
import {IHttpClient} from "../../.."
import {CategoryModel} from '../../../../models/api/categorisation'
import CategoryWithChildrenModel from '../../../../models/api/admin/categories/CategoryWithChildrenModel'

class AdminCategoriesAPI extends APIBase {
    constructor(httpClient: IHttpClient) {
        super(httpClient);
    }

    GetBaseCategories(): Promise<CategoryModel[]> {
        return this.GET({Path: '/admin/categories/base'})
    }

    GetCategoriesWithChildren(categoryId: string): Promise<CategoryWithChildrenModel> {
        return this.GET({
            Path: `/admin/categories/${categoryId}/children`
        })
    }
}

export default AdminCategoriesAPI
