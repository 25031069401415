import {IHttpClient} from "../../..";
import BusinessBranchWithMembership from '../../../../models/api/admin/memberships/BusinessBranchWithMembership'
import Membership from '../../../../models/api/admin/memberships/Membership'
import APIBase from "../../APIBase";

class AdminMembershipAPI extends APIBase {
    constructor(httpClient: IHttpClient) {
        super(httpClient);
    }

    GetExpiredMemberships(): Promise<BusinessBranchWithMembership[]> {
        return this.GET({Path: '/admin/membership/expired'})
    }

    GetLapsedMemberships(): Promise<Membership[]> {
        return this.GET({Path: '/admin/membership/lapsed'})
    }

    GetNewMemberships(): Promise<BusinessBranchWithMembership[]> {
        return this.GET({Path: '/admin/membership/new'})
    }
}

export default AdminMembershipAPI
