import {BusinessBranchModel} from "../../../models/api/businesses";
import {LoggedInUserModel, LoginConfigResult, LoginModel, LoginResult, UserModel} from "../../../models/api/users";
import UpdatePasswordModel from '../../../models/api/users/UpdatePasswordModel'
import UpdateUserModel from '../../../models/api/users/UpdateUserModel'
import UserCreateModel from '../../../models/api/users/UserCreateModel'
import IHttpClient from "../../httpclient/IHttpClient";
import APIBase from "../APIBase";

class UsersAPI extends APIBase {
    constructor(httpClient: IHttpClient) {
        super(httpClient);
    }

    Me(): Promise<LoggedInUserModel> {
        return this.GET<LoggedInUserModel>({
            Path: '/users/me',
        })
    }

    Register(model: UserCreateModel): Promise<LoggedInUserModel> {
        return this.POST({
            Path: '/users/register',
            Body: model,
            Anon: true
        })
    }

    Validate(model: UserCreateModel): Promise<boolean> {
        return this.POST({
            Path: '/users/validate',
            Body: model,
            Anon: true
        })
    }

    UpdateUser(userId: string, model: UpdateUserModel): Promise<UserModel> {
        return this.POST({
            Path: `/users/${userId}`,
            Body: model,
        })
    }

    UpdatePassword(userId: string, model: UpdatePasswordModel): Promise<boolean> {
        return this.POST({
            Path: `/users/${userId}/password`,
            Body: model,
        })
    }

    ConfigureLogin(): Promise<LoginConfigResult> {
        return this.GET({
            Path: '/users/login_config',
            Anon: true
        })
    }

    Login(model: LoginModel): Promise<LoginResult> {
        return this.POST({
            Path: '/users/login',
            Body: model,
            Anon: true
        })
    }

    Logout(): Promise<boolean> {
        return this.POST({
            Path: '/users/logout',
        });
    }

    GetBusinessBranches(): Promise<BusinessBranchModel[]> {
        return this.GET({
            Path: '/users/me/business_branches'
        })
    }

    GetCookie(tokenId: string): Promise<boolean> {
        return this.GET({
            Path: `/users/set-cookie/${tokenId}`,
        })
    }
}

export default UsersAPI;
