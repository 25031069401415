
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
  }
})

export default class FlexGroup extends Vue {
  @Prop({ default: 'start' })
  private readonly justify!: 'start' | 'end' | 'space-around' | 'space-between'

  @Prop({ default: 'center' })
  private readonly align!: 'start' | 'center' | 'end' | 'stretch'

  @Prop({ default: 'medium' })
  private readonly spacing!: 'small' | 'medium' | 'large';

  @Prop({ default: false })
  private readonly isColumn!: boolean;

  @Prop({ default: false })
  private readonly isWrap!: boolean;
}

