import {IHttpClient} from "../.."
import BusinessBranchFilter from "../../../filters/BusinessBranchFilter"
import {
    BusinessBranchContactMethod,
    BusinessBranchModel,
    BusinessBranchOpeningTime,
    BusinessBranchSocialLink,
    BusinessModel,
    CreateBusinessModel,
    CreateBusinessResultModel,
    CreateOpeningTime,
    UpsertBusinessBranchContactMethod,
    UpsertBusinessContent
} from "../../../models/api/businesses"
import ServiceOfferedUpsertDto from '../../../models/api/businesses/branches/ServiceOffered/ServiceOfferedUpsertDto'
import ServiceOfferedReadDto from '../../../models/api/businesses/branches/ServiceOffered/ServiceOfferedReadDto'
import CreateAdditionalDetailsModel from '../../../models/api/businesses/CreateAdditionalDetailsModel'
import CreateBusinessBranchModel from '../../../models/api/businesses/CreateBusinessBranchModel'
import CreateServicesOfferedModel from '../../../models/api/businesses/CreateServicesOfferedModel'
import UpsertSocialLinkModel from '../../../models/api/businesses/UpsertSocialLinkModel'
import {CategoryModel} from "../../../models/api/categorisation"
import {PagedResultSet} from "../../../models/api/common"
import APIBase from "../APIBase"
import LicenseQualificationReadDto
    from '../../../models/api/businesses/branches/LicenseQualification/LicenseQualificationReadDto'
import LicenseQualificationUpsertDto
    from '../../../models/api/businesses/branches/LicenseQualification/LicenseQualificationUpsertDto'
import BusinessBranchCard from '../../../models/api/businesses/BusinessBranchCard'
import BusinessBranchRatingUpsertDto from '../../../models/api/businesses/branches/Rating/BusinessBranchRatingUpsertDto'
import BusinessBranchRatingCreatedDto from '../../../models/api/businesses/branches/Rating/BusinessBranchRatingReadDto'
import BusinessBranchDetailUpdateModel from '../../../models/api/businesses/BusinessBranchDetailUpdateModel'
import BusinessBranchIntroductionUpdateDto
    from '../../../models/api/businesses/branches/BusinessBranchIntroductionUpdateDto'
import BusinessBranchHeadlineUpdateDto from '../../../models/api/businesses/branches/BusinessBranchHeadlineUpdateDto'
import BusinessBranchBodyUpdateDto from '../../../models/api/businesses/branches/BusinessBranchBodyUpdateDto'
import BusinessBranchMainZoneUpdateDto from '../../../models/api/businesses/BusinessBranchMainZoneUpdateDto'
import {ZoneModel} from '../../../models/api/admin'
import BusinessBranchFeedbackCreateDto
    from "../../../models/api/businesses/branches/Feedback/BusinessBranchFeedbackCreateDto";
import BusinessBranchFeedbackFilter from '../../../filters/BusinessBranchFeedbackFilter'
import BusinessBranchWithFeedbackReadDto
    from '../../../models/api/businesses/branches/Feedback/BusinessBranchWithFeedbackReadDto'
import BusinessBranchSearchResponse from '../../../models/api/businesses/BusinessBranchSearchResponse'
import BusinessBranchNpcUpdate from "../../../models/api/businesses/branches/BusinessBranchNpcUpdate";
import BusinessBranchABNUpdate from "../../../models/api/businesses/branches/BusinessBranchABNUpdate";


class BusinessAPI extends APIBase {
    constructor(httpClient: IHttpClient) {
        super(httpClient);
    }

    GetBusiness(id: string): Promise<BusinessModel> {
        return this.GET({
            Path: `/businesses/${id}`
        })
    }

    GetBusinessBranches(businessId: string): Promise<BusinessBranchModel[]> {
        return this.GET({
            Path: `/businesses/${businessId}/branches`
        })
    }

    CreateBusiness(createBusinessModel: CreateBusinessModel): Promise<CreateBusinessResultModel> {
        return this.POST({
            Path: '/businesses',
            Body: createBusinessModel
        })
    }

    UpdateBusinessBranchDetails(branchId: string, model: BusinessBranchDetailUpdateModel): Promise<BusinessBranchModel> {
        return this.PATCH({
            Path: `/businesses/update-details/${branchId}`,
            Body: model
        })
    }

    ValidateBusiness(model: CreateBusinessModel): Promise<boolean> {
        return this.POST({
            Path: '/businesses/validate/business',
            Body: model
        })
    }

    ValidateBranch(model: CreateBusinessBranchModel): Promise<boolean> {
        return this.POST({
            Path: '/businesses/validate/branch',
            Body: model
        })
    }

    ValidateAdditionalDetails(model: CreateAdditionalDetailsModel): Promise<boolean> {
        return this.POST({
            Path: '/businesses/validate/additional-details',
            Body: model
        })
    }

    ValidateServicesOffered(model: CreateServicesOfferedModel): Promise<boolean> {
        return this.POST({
            Path: '/businesses/validate/services-offered',
            Body: model
        })
    }

    // ValidateContacts(model: CreateContactDetailFormModel): Promise<boolean> {
    //   return this.POST({
    //     Path: '/api/businesses/validate/contact',
    //     Body: model
    //   })
    // }

    GetBranch(branchId: string): Promise<BusinessBranchModel> {
        return this.GET({
            Path: `/businesses/branches/${branchId}`
        })
    }

    GetBranchBySlug(slug: string): Promise<BusinessBranchModel> {
        return this.GET({
            Anon: true,
            Path: `/businesses/branches/slug/${slug}`
        })
    }

    SearchBranchesForPublic(filter: BusinessBranchFilter): Promise<BusinessBranchSearchResponse> {
        return this.GET({
            Anon: true,
            Path: '/businesses/branches',
            QueryParams: filter.ToQueryParams()
        })
    }

    SearchBranches(filter: BusinessBranchFilter): Promise<BusinessBranchSearchResponse> {
        return this.GET({
            Path: '/businesses/branches',
            QueryParams: filter.ToQueryParams()
        })
    }

    // Some content stuff
    UpdateHeadline(branchId: string, headlineDto: BusinessBranchHeadlineUpdateDto): Promise<BusinessBranchHeadlineUpdateDto> {
        return this.PATCH({
            Path: `/businesses/branches/${branchId}/headline`,
            Body: headlineDto
        })
    }

    UpdateBody(branchId: string, bodyDto: BusinessBranchBodyUpdateDto): Promise<BusinessBranchBodyUpdateDto> {
        return this.PATCH({
            Path: `/businesses/branches/${branchId}/body`,
            Body: bodyDto
        })
    }

    UpdateIntroduction(branchId: string, dto: BusinessBranchIntroductionUpdateDto): Promise<BusinessBranchIntroductionUpdateDto> {
        return this.PATCH({
            Path: `/businesses/branches/${branchId}/introduction`,
            Body: dto
        })
    }

    // Opening Times
    GetOpeningTimes(branchId: string): Promise<BusinessBranchOpeningTime[]> {
        return this.GET({
            Path: `/businesses/branches/${branchId}/opening_times`,
        })
    }

    AddOpeningTime(branchId: string, model: CreateOpeningTime): Promise<BusinessBranchOpeningTime> {
        return this.POST({
            Path: `/businesses/branches/${branchId}/opening_times`,
            Body: model
        })
    }

    UpdateOpeningTime(branchId: string, model: BusinessBranchOpeningTime): Promise<BusinessBranchOpeningTime> {
        return this.PATCH({
            Path: `/businesses/branches/${branchId}/opening_times/`,
            Body: model
        })
    }

    DeleteOpeningTime(branchId: string, timeId: string): Promise<BusinessBranchOpeningTime> {
        return this.DELETE({
            Path: `/businesses/branches/${branchId}/opening_times/${timeId}`,
        })
    }

    // End Opening Times
    // Social Medias
    GetSocialLinks(branchId: string): Promise<BusinessBranchSocialLink[]> {
        return this.GET({
            Path: `/businesses/branches/${branchId}/social-links`,
        })
    }

    AddSocialLink(branchId: string, model: UpsertSocialLinkModel): Promise<BusinessBranchSocialLink> {
        return this.POST({
            Path: `/businesses/branches/${branchId}/social-links/`,
            Body: model,
        })
    }

    UpdateSocialLink(branchId: string, socialId: string, model: UpsertSocialLinkModel): Promise<BusinessBranchSocialLink> {
        return this.PATCH({
            Path: `/businesses/branches/${branchId}/social-links/${socialId}`,
            Body: model,
        })
    }

    DeleteSocialLink(branchId: string, socialId: string): Promise<BusinessBranchSocialLink> {
        return this.DELETE({
            Path: `/businesses/branches/${branchId}/social-links/${socialId}`,
        })
    }

    // End Social Medias

    MainZoneUpdate(branchId: string, dto: BusinessBranchMainZoneUpdateDto): Promise<ZoneModel> {
        return this.PATCH({
            Path: `/businesses/branches/${branchId}/main-zone`,
            Body: dto
        })
    }

    // Start Categories
    GetBranchCategories(branchId: string): Promise<CategoryModel[]> {
        return this.GET({
            Path: `/businesses/branches/${branchId}/categories`
        })
    }

    AddBusinessBranchCategory(branchId: string, categoryId: string): Promise<CategoryModel> {
        return this.POST({
            Path: `/businesses/branches/${branchId}/categories/${categoryId}`
        })
    }

    DeleteBusinessBranchCategory(branchId: string, categoryId: string): Promise<CategoryModel> {
        return this.DELETE({
            Path: `/businesses/branches/${branchId}/categories/${categoryId}`
        })
    }

    // End Categories

    // Contact Methods
    CreateContactMethod(branchId: string, model: UpsertBusinessBranchContactMethod): Promise<BusinessBranchContactMethod> {
        return this.POST({
            Path: `/businesses/branches/${branchId}/contact-methods`,
            Body: model,
        })
    }

    UpdateContactMethod(branchId: string, contactMethodId: string, model: UpsertBusinessBranchContactMethod): Promise<BusinessBranchContactMethod> {
        return this.PATCH({
            Path: `/businesses/branches/${branchId}/contact-methods/${contactMethodId}`,
            Body: model,
        })
    }

    DeleteContactMethod(branchId: string, contactMethodId: string): Promise<BusinessBranchContactMethod> {
        return this.DELETE({
            Path: `/businesses/branches/${branchId}/contact-methods/${contactMethodId}`
        })
    }

    // End Contact Methods
    // Start Business Content
    UpsertContent(branchId: string, contentModel: UpsertBusinessContent): Promise<boolean> {
        return this.PATCH({
            Path: `/businesses/branches/${branchId}/content`,
            Body: contentModel,
        })
    }

    // End Business Content

    GetRecentBranchesForPublic(branchCount: number): Promise<BusinessBranchModel[]> {
        return this.GET({
            Anon: true,
            Path: `/businesses/recent/${branchCount}`
        })
    }

    GetNewBranches(count: number): Promise<BusinessBranchModel[]> {
        return this.GET({
            Path: `/businesses/new/${count}`
        })
    }

    // Start TradingOptions
    CreateTradingOption(branchId: string, categoryId: string): Promise<CategoryModel> {
        return this.POST({
            Path: `/businesses/branches/${branchId}/trading-options/${categoryId}`
        })
    }

    DeleteTradingOption(branchId: string, categoryId: string): Promise<CategoryModel> {
        return this.DELETE({
            Path: `/businesses/branches/${branchId}/trading-options/${categoryId}`
        })
    }

    // End TradingOptions
    // Region ServicesOffered
    ServiceOfferedCreate(branchId: string, serviceOfferedDto: ServiceOfferedUpsertDto): Promise<ServiceOfferedReadDto> {
        return this.POST({
            Path: `/businesses/branches/${branchId}/service-offered`,
            Body: serviceOfferedDto
        })
    }

    ServiceOfferedUpdate(branchId: string, serviceOfferedId: string, serviceOfferedDto: ServiceOfferedUpsertDto): Promise<ServiceOfferedReadDto> {
        return this.PATCH({
            Path: `/businesses/branches/${branchId}/service-offered/${serviceOfferedId}`,
            Body: serviceOfferedDto
        })
    }

    ServiceOfferedDelete(branchId: string, serviceOfferedId: string): Promise<ServiceOfferedReadDto> {
        return this.DELETE({
            Path: `/businesses/branches/${branchId}/service-offered/${serviceOfferedId}`
        })
    }

    // End Region ServicesOffered
    // Region LicenseQualification
    LicenseQualificationCreate(branchId: string, licenseQualificationCreateDto: LicenseQualificationUpsertDto): Promise<LicenseQualificationReadDto> {
        return this.POST({
            Path: `/businesses/branches/${branchId}/license-qualification`,
            Body: licenseQualificationCreateDto
        })
    }

    LicenseQualificationUpdate(branchId: string, licenseQualificationId: string, licenseQualificationCreateDto: LicenseQualificationUpsertDto): Promise<LicenseQualificationReadDto> {
        return this.PATCH({
            Path: `/businesses/branches/${branchId}/license-qualification/${licenseQualificationId}`,
            Body: licenseQualificationCreateDto
        })
    }

    LicenseQualificationDelete(branchId: string, licenseQualificationId: string): Promise<LicenseQualificationReadDto> {
        return this.DELETE({
            Path: `/businesses/branches/${branchId}/license-qualification/${licenseQualificationId}`
        })
    }

    // End Region LicenseQualification

    // Region ratings
    UpsertRating(branchId: string, ratingUpsertDto: BusinessBranchRatingUpsertDto): Promise<BusinessBranchRatingCreatedDto> {
        return this.POST({
            Path: `/businesses/branches/${branchId}/rating`,
            Body: ratingUpsertDto,
            Anon: true
        })
    }

    // End Region ratings

//   #region Membership
    MembershipCancel(id: string): Promise<BusinessBranchModel> {
        return this.PATCH({
            Path: `/businesses/branches/${id}/cancel`
        })
    }

    // #endregion Membership


//   region Feedback
    FeedbackCreate(id: string, dto: BusinessBranchFeedbackCreateDto): Promise<BusinessBranchModel> {
        return this.POST({
            Path: `/businesses/branches/${id}/feedback`,
            Body: dto
        })
    }

    FeedbackQuery(filter: BusinessBranchFeedbackFilter): Promise<PagedResultSet<BusinessBranchWithFeedbackReadDto>> {
        return this.GET({
            Path: '/businesses/feedback',
            QueryParams: filter.ToQueryParams()
        })
    }

    // endregion Feedback
    // region details
    UpdateNpc (id: string, updateDto: BusinessBranchNpcUpdate): Promise<BusinessBranchNpcUpdate> {
        return this.PATCH({
            Path: `/businesses/update-npc/${id}`,
            Body: updateDto
        })
    }

    UpdateAbn (id: string, updateDto: BusinessBranchABNUpdate): Promise<BusinessBranchABNUpdate> {
        return this.PATCH({
            Path: `/businesses/update-abn/${id}`,
            Body: updateDto
        })
    }
    // endregion details
}

export default BusinessAPI
