import Vue from 'vue'

// layout
import Column from '@/components/layout/Column.vue'
import Row from '@/components/layout/Row.vue'
import ContentContainer from '@/components/layout/ContentContainer.vue'
import ContentRow from '@/components/layout/ContentRow.vue'
import FS from '@/components/layout/FS.vue'

// ui
import Alert from './status/Alert.vue'
import Avatar from '@/components/ui/Avatar.vue'
import Button from './actions/Button.vue'
import ButtonGroup from './actions/ButtonGroup.vue'
import ContentSection from '@/components/layout/ContentSection.vue'
import Heading from './typography/Heading.vue'
import Hero from '@/components/layout/Hero.vue'
import Icon from './typography/Icon.vue'
import InfoState from './status/InfoState.vue'
import LazyImage from '@/components/ui/LazyImage.vue'
import Loader from './status/Loader.vue'
import PageTitle from './typography/PageTitle.vue'

// Base
import FlexGroup from '@/components/layout/FlexGroup.vue'

// layout
Vue.component('Column', Column)
Vue.component('Row', Row)
Vue.component('ContentContainer', ContentContainer)
Vue.component('ContentRow', ContentRow)
Vue.component('FS', FS)

// ui
Vue.component('Alert', Alert)
Vue.component('Avatar', Avatar)
Vue.component('Button', Button)
Vue.component('ButtonGroup', ButtonGroup)
Vue.component('ContentSection', ContentSection)
Vue.component('Heading', Heading)
Vue.component('Hero', Hero)
Vue.component('Icon', Icon)
Vue.component('InfoState', InfoState)
Vue.component('LazyImage', LazyImage)
Vue.component('Loader', Loader)
Vue.component('PageTitle', PageTitle)

// Base
Vue.component('FlexGroup', FlexGroup)
