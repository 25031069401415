import IHttpClient from "../../../httpclient/IHttpClient";
import AdminBusinessesAPI from './AdminBusinessesAPI'
import AdminCategoriesAPI from './AdminCategoriesAPI'
import AdminContentAPI from './AdminContentAPI'
import AdminCountriesAPI from "./AdminCountriesAPI";
import AdminEmailAPI from './AdminEmailAPI'
import AdminMembershipAPI from './AdminMembershipAPI'
import AdminProductAPI from './AdminProductAPI'
import AdminQuoteRequestAPI from './AdminQuoteRequestAPI'
import AdminUserAPI from './AdminUserAPI'
import AdminWebsiteAPI from './AdminWebsiteAPI'
import AdminZonesAPI from "./AdminZonesAPI";
import FranchiseAPI from "./FranchiseAPI";

class AdminAPI {
    Countries: AdminCountriesAPI;
    Content: AdminContentAPI;
    Zones: AdminZonesAPI;
    Franchises: FranchiseAPI;
    Users: AdminUserAPI;
    Membership: AdminMembershipAPI;
    Categories: AdminCategoriesAPI;
    QuoteRequest: AdminQuoteRequestAPI;
    Product: AdminProductAPI;
    Businesses: AdminBusinessesAPI;
    Emails: AdminEmailAPI;
    Websites: AdminWebsiteAPI;

    constructor(httpClient: IHttpClient) {
        this.Countries = new AdminCountriesAPI(httpClient);
        this.Content = new AdminContentAPI(httpClient);
        this.Businesses = new AdminBusinessesAPI(httpClient);
        this.Categories = new AdminCategoriesAPI(httpClient);
        this.Emails = new AdminEmailAPI(httpClient);
        this.Franchises = new FranchiseAPI(httpClient);
        this.Membership = new AdminMembershipAPI(httpClient);
        this.Product = new AdminProductAPI(httpClient);
        this.QuoteRequest = new AdminQuoteRequestAPI(httpClient);
        this.Users = new AdminUserAPI(httpClient);
        this.Websites = new AdminWebsiteAPI(httpClient);
        this.Zones = new AdminZonesAPI(httpClient);
    }
}

export default AdminAPI
