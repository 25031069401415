
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Loader extends Vue {
  @Prop({ default: true })
  private readonly loading!: boolean

  @Prop({ default: '2em' })
  private readonly diameter!: string

  @Prop({ default: 'large' })
  private readonly size!: 'small' | 'medium' | 'large';

  @Prop({ default: false })
  private readonly inverted!: boolean;
}
