import {IHttpClient} from "../..";
import CheckoutSessionCreated from '../../../models/api/payment/stripe/CheckoutSessionCreated'
import StripeClientConfig from '../../../models/api/payment/stripe/StripeClientConfig'
import APIBase from "../APIBase";
import PaymentIntentCreateRequest from '../../../models/api/payment/stripe/PaymentIntentCreateRequest';
import PaymentIntentCreated from '../../../models/api/payment/stripe/PaymentIntentCreated';
import StripeSessionCreateOptionsModel
    from '../../../models/api/payment/stripe/checkout/StripeSessionCreateOptionsModel'
import StripePortalCreateOptionsModel from '../../../models/api/payment/stripe/checkout/StripePortalCreateOptionsModel'
import StripePortalSessionModel from '../../../models/api/payment/stripe/portal/StripePortalSessionModel'
import Packages from '../../../models/api/payment/Packages'
import PackageSignupResponse from '../../../models/api/payment/PackageSignupResponse'
import PackageSignupRequest from '../../../models/api/payment/PackageSignupRequest'
import SignupCheckoutRequest from "../../../models/api/signup/SignupCheckoutRequest";

class PaymentAPI extends APIBase {
    constructor(httpClient: IHttpClient) {
        super(httpClient);
    }

    GetRenewalLink (id: string): Promise<{Url : string}> {
        return this.POST({
            Path: `/payment/renewal/${id}`,
            Anon: true
        })
    }

    // Products
    GetStripeClientConfig(): Promise<StripeClientConfig> {
        return this.GET({
            Path: '/payment/config',
        })
    }

    CreatePaymentIntent(paymentIntent: PaymentIntentCreateRequest): Promise<PaymentIntentCreated> {
        return this.POST({
            Path: '/payment/intent',
            Body: paymentIntent
        })
    }

    CheckoutCart(sessionCreateOptions: StripeSessionCreateOptionsModel): Promise<CheckoutSessionCreated> {
        return this.POST({
            Path: '/payment/checkout',
            Body: sessionCreateOptions
        })
    }

    SignupPackage(selectedPackage: Packages): Promise<CheckoutSessionCreated> {
        return this.POST({
            Path: '/payment/package',
            Body: selectedPackage
        })
    }

    CreatePortalSession(businessBranchId: string, portalCreateOptions: StripePortalCreateOptionsModel): Promise<StripePortalSessionModel> {
        return this.POST({
            Path: `/payment/${businessBranchId}/portal`,
            Body: portalCreateOptions
        })
    }

    PackageSignup(branchId: string, dto: PackageSignupRequest): Promise<PackageSignupResponse> {
        return this.POST({
            Path: `/payment/package/${branchId}`,
            Body: dto
        })
    }

    CheckoutRequest (bbid: string, request: SignupCheckoutRequest): Promise<CheckoutSessionCreated> {
        return this.POST({
            Path: `/payment/checkout/${bbid}`,
            Body: request
        })
    }

    PayLaterRequest(bbid: string, request: SignupCheckoutRequest): Promise<boolean>
    {
        return this.POST({
            Path: `/payment/pay-later/${bbid}`,
            Body: request
        })
    }

}

export default PaymentAPI
