import {IHttpClient} from "../..";
import {
    CreateUploadSessionModel,
    FileAttachmentType,
    FileUploadProgressModel,
    GetFileModel,
    UploadSessionModel
} from "../../../models/api/files";
import AttachedObjectType from '../../../models/api/files/AttachedObjectType'
import APIBase from "../APIBase";
import FileUpdateDto from '../../../models/api/files/FileUpdateDto'

class FileAPI extends APIBase {
    constructor(httpClient: IHttpClient) {
        super(httpClient);
    }

    CreateUploadSession(model: CreateUploadSessionModel): Promise<UploadSessionModel> {
        return this.POST({
            Path: '/files/upload/sessions',
            Body: model
        })
    }

    UploadChunk(sessionId: string, chunk: number, data: Blob): Promise<FileUploadProgressModel> {
        return this.POST({
            Path: `/files/upload/sessions/${sessionId}/chunks/${chunk}`,
            Body: data
        })
    }

    CommitSession(sessionId: string): Promise<GetFileModel> {
        return this.POST({
            Path: `/files/upload/sessions/${sessionId}/commit`
        })
    }

    Upload(file: File, isImage: boolean): Promise<GetFileModel> {
        return new Promise((resolve, reject) => {

            this.CreateUploadSession({
                Size: file.size,
                IsImage: isImage,
                Filename: file.name,
                ContentType: file.type
            }).then((session) => {

                const chunks: Blob[] = []
                let pos = 0

                for (let i = 0; i < session.Chunks; i += 1) {
                    chunks.push(file.slice(pos, pos + session.ChunkSize));
                    pos += session.ChunkSize;
                }

                let chunkIdx = 0

                const uploadNext = () => {
                    this.UploadChunk(session.SessionId, chunkIdx, chunks[chunkIdx]).then((res) => {
                        if (res.Completed === res.Total) {

                            this.CommitSession(session.SessionId).then(resolve).catch(reject)

                        } else {
                            chunkIdx += 1
                            uploadNext()
                        }
                    }).catch(reject)
                }

                uploadNext()

            }).catch(reject)

        })
    }

    GetForObject(objectId: string, objectType: AttachedObjectType, attachmentType: FileAttachmentType): Promise<GetFileModel[]> {
        return this.POST({
            Path: '/files/from_object',
            Body: {
                FileAttachmentType: attachmentType,
                ObjectId: objectId,
                ObjectType: objectType
            }
        })
    }

    Update (fileId: string, fileUpdate: FileUpdateDto): Promise<GetFileModel> {
        return this.PATCH({
            Path: `/files/${fileId}`,
            Body: fileUpdate
        })
    }

    Attach(fileId: string, objectId: string, objectType: AttachedObjectType, attachmentType: FileAttachmentType, order: number): Promise<boolean> {
        return this.POST({
            Path: `/files/${fileId}/attachments`,
            Body: {
                AttachedObjectId: objectId,
                AttachedObjectType: objectType,
                AttachmentType: attachmentType,
                Order: order
            }
        })
    }

    Detach(fileId: string, objectId: string, objectType: AttachedObjectType, attachmentType: FileAttachmentType): Promise<boolean> {
        return this.DELETE({
            Path: `/files/${fileId}/attachments`,
            Body: {
                AttachedObjectId: objectId,
                AttachedObjectType: objectType,
                AttachmentType: attachmentType,
                Order: 0
            }
        })
    }
}

export default FileAPI
