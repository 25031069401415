import {Services} from "..";
import {Config} from "../../environment";
import CredentialManager from "../credentialmanager/CredentialManager";
import HttpRequest from "../httpclient/HttpRequest";
import IHttpClient from "../httpclient/IHttpClient";
import APIRequestInit from "./APIRequestInit";

class APIBase {
    protected HttpClient: IHttpClient;
    protected CredentialManager: CredentialManager;

    constructor(httpClient: IHttpClient) {
        this.HttpClient = httpClient;
        this.CredentialManager = Services.CredentialManager;
    }

    protected GetUrl(path: string): string {
        return `${Config.API.Base}${path}`;
    }

    protected GET<TResponse>(init: APIRequestInit): Promise<TResponse> {
        const req = HttpRequest.GET(this.GetUrl(init.Path), init.QueryParams, init.CacheMode).WithHeaders(init.Headers)

        if (!init.Anon) {
            const credential = this.CredentialManager.GetOAuthCredential();

            if (credential === null) {
                return Promise.reject('No credentials available')
            }

            req.Headers['Authorization'] = `Bearer oauth:${credential.Token}`;
        }

        return this.HttpClient.Request<TResponse>(req);
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    protected POST<TResponse>(init: APIRequestInit): Promise<TResponse> {
        const req = HttpRequest.POST(this.GetUrl(init.Path), init.Body).WithHeaders(init.Headers);

        if (!init.Anon) {
            const credential = this.CredentialManager.GetOAuthCredential();

            if (credential === null) throw new Error('No credential available');

            req.Headers['Authorization'] = `Bearer oauth:${credential.Token}`;
        }

        return this.HttpClient.Request<TResponse>(req);
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    protected PATCH<TResponse>(init: APIRequestInit): Promise<TResponse> {
        const req = HttpRequest.PATCH(this.GetUrl(init.Path), init.QueryParams, init.Body).WithHeaders(init.Headers);

        if (!init.Anon) {
            const credential = this.CredentialManager.GetOAuthCredential();

            if (credential === null) throw new Error('No credential available');

            req.Headers['Authorization'] = `Bearer oauth:${credential.Token}`;
        }

        return this.HttpClient.Request<TResponse>(req);
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    protected DELETE<TResponse>(init: APIRequestInit): Promise<TResponse> {
        const req = HttpRequest.DELETE(this.GetUrl(init.Path), init.Body).WithHeaders(init.Headers);

        if (!init.Anon) {
            const credential = this.CredentialManager.GetOAuthCredential();

            if (credential === null) throw new Error('No credential available');

            req.Headers['Authorization'] = `Bearer oauth:${credential.Token}`;
        }

        return this.HttpClient.Request<TResponse>(req);
    }
}

export default APIBase;
