import ProductFilter from '../../../filters/ProductFilter'
import ZoneModel from '../../../models/api/admin/ZoneModel'
import {PagedResultSet} from '../../../models/api/common'
import CreateProductAttributeModel from '../../../models/api/products/CreateProductAttributeModel'
import ProductAttribute from '../../../models/api/products/ProductAttribute'
import ProductCategoryModel from '../../../models/api/products/categories/ProductCategoryModel'
import ProductDetailsModel from '../../../models/api/products/ProductDetailsModel'
import ProductSummaryModel from '../../../models/api/products/ProductSummaryModel'
import UpdateProductAttributeModel from '../../../models/api/products/UpdateProductAttributeModel'
import IHttpClient from '../../httpclient/IHttpClient'
import APIBase from '../APIBase'
import CreateProductCategoryModel from '../../../models/api/products/categories/CreateProductCategoryModel'
import StripeProductCreate from '../../../models/api/admin/stripe/products/StripeProductCreate'
import StripeProduct from '../../../models/api/admin/stripe/products/StripeProduct'

export default class ProductAPI extends APIBase {
    constructor(httpClient: IHttpClient) {
        super(httpClient);
    }

    GetProducts(filter: ProductFilter): Promise<PagedResultSet<ProductSummaryModel>> {
        return this.GET({
            Path: `/products/`,
            QueryParams: filter.ToQueryParams()
        })
    }

    CreateProduct(model: StripeProductCreate): Promise<StripeProduct> {
        return this.POST({
            Path: `/admin/products/`,
            Body: model
        })
    }

    GetAdminProduct(id: string): Promise<ProductDetailsModel> {
        return this.GET({
            Path: `/admin/products/${id}`
        })
    }

    AddProductAttribute(productId: string, createModel: CreateProductAttributeModel): Promise<ProductAttribute> {
        return this.POST({
            Path: `/admin/products/${productId}/attributes`,
            Body: createModel
        })
    }

    DeleteProductAttribute(productId: string, attributeId: string): Promise<string> {
        return this.DELETE({
            Path: `/admin/products/${productId}/attributes/${attributeId}`
        })
    }

    UpdateProductAttibute(productId: string, attributeId: string, model: UpdateProductAttributeModel): Promise<ProductAttribute> {
        return this.PATCH({
            Path: `/admin/products/${productId}/attributes/${attributeId}`,
            Body: model
        })
    }

    // Zones
    AddProductZone(productId: string, zoneId: string): Promise<ZoneModel> {
        return this.POST({
            Path: `/admin/products/${productId}/zones/${zoneId}`
        })
    }

    DeleteProductZone(productId: string, zoneId: string): Promise<ZoneModel> {
        return this.DELETE({
            Path: `/admin/products/${productId}/zones/${zoneId}`
        })
    }

    // Categories
    GetProductCategories(): Promise<ProductCategoryModel[]> {
        return this.GET({
            Path: `/admin/products/categories`
        })
    }

    AddProductInCategory(productId: string, categoryId: string): Promise<ProductCategoryModel> {
        return this.POST({
            Path: `/admin/products/${productId}/categories/${categoryId}`
        })
    }

    RemoveProductInCategory(productId: string, categoryId: string): Promise<ProductCategoryModel> {
        return this.DELETE({
            Path: `/admin/products/${productId}/categories/${categoryId}`
        })
    }

    CreateProductCategory(createModel: CreateProductCategoryModel): Promise<ProductCategoryModel> {
        return this.POST({
            Path: `/admin/products/categories/`,
            Body: createModel
        })
    }

    DeleteProductCategory(productId: string, categoryId: string): Promise<ProductCategoryModel> {
        return this.DELETE({
            Path: `/admin/products/${productId}/categories/${categoryId}`
        })
    }
}
