
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Alert extends Vue {
  @Prop({ default: 'info' })
  private readonly type!: string;

  @Prop()
  private readonly icon!: string;
}
