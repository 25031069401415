import {OnDemandService} from "./IService";
import ServiceType from "./ServiceTypes";

class ServiceFactory<TService extends OnDemandService> {
    public readonly Type: ServiceType;
    private readonly Factory: () => TService;

    constructor(type: ServiceType, factory: () => TService) {
        this.Type = type;
        this.Factory = factory;
    }

    public GetService(): TService {
        return this.Factory();
    }
}

export default ServiceFactory;
