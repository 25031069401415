import BusinessBranchStateInfo from "./models/api/businesses/BusinessBranchStates2";

class Utils {
    static characters: string[] = [
        'man_holding_laptop.png',
        'man_holding_toolbox.png',
        'woman_holding_clipboard.png',
        'woman_holding_phone.png'
    ]

    static GetGuid(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0
            const v = c === 'x' ? r : (r & 0x3 | 0x8)

            return v.toString(16)
        })
    }

    static GetId(): string {
        return `${Math.random().toString(36).substr(2, 9)}`
    }

    static isoToDisplayDate(iso: string): string {
        return (new Date(iso)).toLocaleDateString('en-GB', {day: 'numeric', month: 'numeric', year: 'numeric'});
    }

    static isoToDisplayTime(iso: string): string | null {
        const date = new Date((new Date().setHours(0, 0, 0, 0)))
        const newTime = date.toLocaleTimeString(undefined, {hour12: true, hour: '2-digit', minute: '2-digit'});
        const time = (new Date(iso)).toLocaleTimeString(undefined, {hour12: true, hour: '2-digit', minute: '2-digit'});
        if (newTime === time) return null
        return time;
    }

    static toDateIso(date_: string, time: string): string {
        const date = new Date(date_ + ' ' + time)
        date.setMonth(date.getMonth() + 1)
        return date.toISOString()
    }

    static isoToDateInput(iso: string): string {
        const date = new Date(iso);
        return date.getFullYear() + '-' + this.formatNum((date.getMonth() + 1)) + '-' + this.formatNum(date.getDate());
    }

    static isoToTimeInput(iso: string): string {
        const date = new Date(iso);
        return this.formatNum(date.getHours()) + ':' + this.formatNum((date.getMinutes()));
    }

    static formatNum(value: number): string {
        return (value < 10 ? '0' : '') + value.toString()
    }

    static Throttle(rate: number, callback: () => void): () => void {
        let lastTime = 0

        return () => {
            const now = new Date().getTime()

            if (now - lastTime >= rate) {
                callback()
                lastTime = now
            }
        }
    }

    static ValidateABN(abn: string): boolean {
        const abnParts = abn.split(' ').join('').split('').map(d => parseInt(d))

        if (abnParts.length !== 11) return false

        abnParts[0] = abnParts[0] - 1

        const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19]

        let sum = 0

        for (let i = 0; i < abnParts.length; i++) {
            sum += abnParts[i] * weights[i]
        }

        return sum % 89 === 0
    }

    static getCharacter(): string {
        return `/assets/images/brand/characters/${this.characters[Math.floor(Math.random() * this.characters.length)]}`
    }

    // static mapper<TModel> (source: Object): TModel  {
    //   const base: Record<string, string> = {}
    //   Object.keys(source).forEach((key) => {
    //     const item = Object.getOwnPropertyDescriptor(this, key)
    //     base[key] = item?.value || null
    //   })

    //   return base as unknown as TModel
    // }
    static states: BusinessBranchStateInfo[] = [
        { Id: '3a538c7b-1cbb-4587-8eea-e66fbb7f71f4', Name: 'Queensland', ZoneCount: 19, Price: 176},
        { Id: '21c4507e-3fe0-463a-af83-2cf34290ade3', Name: 'New South Wales', ZoneCount: 28, Price: 176},
        { Id: '661ab8f9-16bf-4b51-b64e-0f50ae1deb7F', Name: 'Victoria', ZoneCount: 17, Price: 176},
        { Id: 'c37D2ec8-7ab4-4cd2-a24c-1442D37820de', Name: 'Tasmania', ZoneCount: 4, Price: 88},
        { Id: 'cf8ac06f-4021-48c5-bcd8-181781fa51f8', Name: 'South Australia', ZoneCount: 7, Price: 154},
        { Id: '0C1ab89e-b800-4913-bf68-fc8cdedadd30', Name: 'Western Australia', ZoneCount: 9, Price: 176},
        { Id: '80009baf-20e6-42d3-9930-4620c1924cD7', Name: 'Northern Territory', ZoneCount: 2, Price: 44},
        { Id: '5a27e46b-f13c-49d9-a6a8-21f96e87713b', Name: 'Australian Capital Territory', ZoneCount: 1, Price: 22}
    ]

    static getState(id: string): BusinessBranchStateInfo | null {
        const state = Utils.states.find(x => x.Id === id)
        return state ?? null
    }

    static getCookie(name: string): string | null {
        const cookie: Record<string, string> = {};
        const cookies: string[] = document.cookie.split(';')
            cookies.forEach(x => {
            const [k,v] = x.split('=');
            cookie[k.trim()] = v;
        })
        return cookie[name];
    }
}

export default Utils
