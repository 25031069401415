import {IHttpClient} from "../..";
import APIBase from "../APIBase";

class EmailAPI extends APIBase {
    constructor(httpClient: IHttpClient) {
        super(httpClient);
    }

}

export default EmailAPI
